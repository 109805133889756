import { Pipe, PipeTransform } from '@angular/core';
import { Culture, Weapon, Class, Character } from '../model/character';
import { environment } from '../../environments/environment';

@Pipe({ name: 'dictionnary' })
export class DictionnaryPipe implements PipeTransform {

    transform(data: any, lang: string, type: string): string {
        let str = type;
        if (typeof data === 'string') {
            str = data;
        } else {
            str = data.id;
        }
        if (type) {
            str += '.' + type;
        }
        return environment.names[lang][str] ? environment.names[lang][str] : str;
    }
}
