import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    Character,
    Class,
    Culture,
    Weapon,
    Tools,
    BackgroundType,
    Ability,
    WeaponType,
    Skill,
    ClassSpeciality,
    Protection,
    ProtectionType,
    WeaponProperty,
    DamageType
} from '../model/character';
import { Observable } from 'rxjs';
import { PennyValue } from '../model/commons/penny';
import { Dice } from '../model/commons';

@Injectable()
export class CharacterService {

    constructor(private http: HttpClient) {
    }

    getDataFile(charName: string): Observable<any> {
        return this.http.get('assets/data/' + charName ? charName : 'Beli' + '.json');
    }

    public getData(charName: string): Character {
        switch (charName) {
            case 'Avada':
                return this.getDataAvada();
            case 'Valandor':
                return this.getDataValandor();
            case 'Beli':
            default:
                return this.getDataBeli();
        }
    }

    private getDataAvada(): Character {
        return Character.loadData(
            {
                name: 'Avada',
                culture: Culture.WOODMEN_OF_WILDERLAND.id,
                background: {
                    type: BackgroundType.DRIVEN_FROM_HOME.id,
                    despair: 1,
                    hope: 1,
                    quality: 1,
                    speciality: 1
                },
                clazz: Class.SLAYER.id,
                clazzSpec: ClassSpeciality.SLAYER_FOE_HAMMER.id,
                level: 4,
                HP: 73,
                proficiencyBonus: 3,
                scores: [
                    [Ability.STR.id, 16],
                    [Ability.DEX.id, 14],
                    [Ability.CON.id, 14],
                    [Ability.INT.id, 8],
                    [Ability.WIS.id, 14],
                    [Ability.CHA.id, 14],
                    [Ability.SHA.id, 0]
                ],
                permaShadow: 0,
                virtues: [],
                mastering: [
                    [Ability.STR.id, 1],
                    [Ability.CON.id, 1],
                    [Skill.ANIMAL_HANDLING.id, 1],
                    [Skill.INTIMIDATION.id, 1],
                    [Skill.MEDICINE.id, 1],
                    [Skill.NATURE.id, 1],
                    [Skill.SURVIVAL.id, 1],
                    [Skill.PERCEPTION.id, 1], // chien
                    [WeaponType.SIMPLE.id, 1],
                    [WeaponType.MARTIAL.id, 1],
                    [ProtectionType.SHIELD.id, 1],
                    [ProtectionType.ARMOUR_MEDIUM.id, 1],
                    [ProtectionType.ARMOUR_HEAVY.id, 1],
                    [ProtectionType.ARMOUR_LIGHT.id, 1],
                ],
                modifiers: [],
                features: [],
                equipment: {
                    money: PennyValue.new(48, 12, 0),
                    weapons: [
                        {
                            id: 'Tranch. du Nord',
                            type: WeaponType.MARTIAL,
                            nbDice: 1,
                            diceType: Dice.d12,
                            dmgModif: 1,
                            hitModif: 1,
                            dmgType: DamageType.SLASHING,
                            ability: Ability.STR,
                            properties: [WeaponProperty.HEAVY,
                            WeaponProperty.TWO_HANDED],
                            weight: 7,
                            cost: PennyValue.new(0, 0, 0)
                        },
                        Weapon.DAGGER,
                        Weapon.SHORT_BOW
                    ],
                    protections: [
                        {
                            enabled: true,
                            protection: {
                                id: 'protection.armour.HEAVY_MAIL',
                                type: ProtectionType.ARMOUR_HEAVY,
                                AC: 16,
                                DexModifMax: 9,
                                requiredStrength: 13,
                                disavantagedStealth: true,
                                weight: 55,
                                cost: PennyValue.new(0, 0, 0)
                            }
                        },
                        {
                            enabled: true,
                            protection: Protection.SLAYER_BODY
                        }
                    ]
                },
                about: {
                    title: 'TODO',
                    origin: 'Village Homme des Bois',
                    age: '22',
                    size: '2 m',
                    weight: '120kg',
                    look: 'Punk à chien charismatique',
                    relations: [
                        'Snorri, Borri et Har : Commerçants Nains voyageant vers Erebor',
                        'Gaïlavira (Rowan) : Cheftaine du village H-B sauvé',
                        'Gandalf : Croisé en voyage',
                        'Haldor et fils Belgo : Homme commerçant escorté d\'Esgaroth à Beorn.',
                        'Dody & Dindy Brandebouc : Tenanciers de l\'auberge de l\'Ouest',
                        'Beorn : Lettre de recommandation + services',
                    ],
                    titles: [
                        'Amis des elfes',
                        'Héros des Bois',
                        'Tegh - Dale'
                    ],
                    patrons: [
                        'Radagast'
                    ],
                    sanctuaries: [
                        'Village Natale',
                        'Fort-Bois',
                        'Bourg-les-Bois',
                        'Dale'
                    ],
                    adventures: [{
                        title: 'Voyage chez les Hommes des Bois',
                        date: '2946, printemps',
                        summary: 'TODO'
                    }]
                }
            }
        );
    }

    private getDataValandor(): Character {
        return Character.loadData(
            {
                name: 'Valandor',
                culture: Culture.ELF_MIRKWOOD.id,
                background: {
                    type: BackgroundType.EMISSARY_OF_YOUR_PEOPLE.id,
                    despair: 1,
                    hope: 1,
                    quality: 1,
                    speciality: 1
                },
                clazz: Class.SCHOLAR.id,
                clazzSpec: ClassSpeciality.SCHOLAR_MASTER_HEALER.id,
                level: 6,
                HP: 43,
                proficiencyBonus: 3,
                scores: [
                    [Ability.STR.id, 10],
                    [Ability.DEX.id, 16],
                    [Ability.CON.id, 12],
                    [Ability.INT.id, 14],
                    [Ability.WIS.id, 16],
                    [Ability.CHA.id, 10],
                    [Ability.SHA.id, 0]
                ],
                permaShadow: 0,
                virtues: [],
                mastering: [
                    [Ability.INT.id, 1],
                    [Ability.WIS.id, 1],
                    [Skill.HISTORY.id, 1],
                    [Skill.LORE.id, 1],
                    [Skill.MEDICINE.id, 2],
                    [Skill.PERCEPTION.id, 1],
                    [Skill.PERSUASION.id, 2],
                    [Skill.STEALTH.id, 1],
                    [Skill.TRADITIONS.id, 2],
                    [WeaponType.SIMPLE.id, 1],
                    [Weapon.BROADSWORD.id, 1],
                    [Weapon.SHORT_SWORD.id, 1],
                    [ProtectionType.ARMOUR_LIGHT.id, 1],
                ],
                avantages: [
                    [Skill.STEALTH.id, 'avantage.WOODEN_STEALTH'],
                ],
                modifiers: [],
                features: [
                    {
                        id: 'skill',
                        desc: 'Description de la  ......................................! .............................'
                    }
                ],
                equipment: {
                    money: PennyValue.new(0, 450, 0),
                    weapons: [
                        Weapon.DAGGER,
                        Weapon.SPEAR,
                        {
                            id: 'Arc de l\'Amitié',
                            type: WeaponType.SIMPLE,
                            nbDice: 1,
                            diceType: Dice.d6,
                            dmgModif: 1,
                            hitModif: 1,
                            dmgType: DamageType.PIERCING,
                            ability: Ability.DEX,
                            properties: [WeaponProperty.AMMUNITION_80_320,
                            WeaponProperty.TWO_HANDED],
                            weight: 2,
                            cost: PennyValue.new(0, 0, 0)
                        }
                    ],
                    protections: [
                        { enabled: true, protection: Protection.LEATHER_JERKIN }
                    ]
                },
                about: {
                    title: 'TODO',
                    age: '120',
                    origin: 'Forêt Noire',
                    size: '2 m',
                    weight: '60 kg',
                    look: 'Confident de Thranduil',
                    relations: [
                        'Snorri, Borri et Har : Commerçants Nains voyageant vers Erebor',
                        'Gaïlavira (Rowan) : Cheftaine du village H-B sauvé',
                        'Gandalf : Croisé en voyage',
                        'Haldor et fils Belgo : Homme commerçant escorté d\'Esgaroth à Beorn.',
                        'Dody & Dindy Brandebouc : Tenanciers de l\'auberge de l\'Ouest',
                        'Beorn : Lettre de recommandation + services',
                    ],
                    titles: [
                        'Amis des elfes',
                        'Héros des Bois'
                    ],
                    patrons: [
                        'Radagast'
                    ],
                    sanctuaries: [
                        'Royaume Elfique',
                        'Fort-Bois',
                        'Bourg-les-Bois',
                        'Erebor',
                        'Rhosgobel'
                    ],
                    adventures: [{
                        title: 'Voyage chez les Hommes des Bois',
                        date: '2946, printemps',
                        summary: 'TODO'
                    }]
                }
            }
        );
    }

    private getDataBeli(): Character {
        return Character.loadData(
            {
                name: 'Beli',
                culture: Culture.DWARF_EREBOR.id,
                background: {
                    type: BackgroundType.THE_HARROWED.id,
                    despair: 1,
                    hope: 1,
                    quality: 1,
                    speciality: 1
                },
                clazz: Class.WANDERER.id,
                clazzSpec: ClassSpeciality.WANDERER_HUNTER_OF_SHADOWS.id,
                level: 6,
                HP: 69,
                proficiencyBonus: 3,
                scores: [
                    [Ability.STR.id, 12],
                    [Ability.DEX.id, 14],
                    [Ability.CON.id, 14],
                    [Ability.INT.id, 14],
                    [Ability.WIS.id, 16],
                    [Ability.CHA.id, 8],
                    [Ability.SHA.id, 0]
                ],
                permaShadow: 0,
                virtues: [],
                mastering: [
                    [Ability.STR.id, 1],
                    [Ability.CON.id, 1],
                    [Skill.INVESTIGATION.id, 1],
                    [Skill.LORE.id, 1],
                    [Skill.NATURE.id, 1],
                    [Skill.PERCEPTION.id, 2],
                    [Skill.STEALTH.id, 1],
                    [Skill.SURVIVAL.id, 1],
                    [Skill.SHADOW_LORE.id, 1],
                    [WeaponType.SIMPLE.id, 1],
                    [WeaponType.MARTIAL.id, 1],
                    [ProtectionType.SHIELD.id, 1],
                    [ProtectionType.ARMOUR_MEDIUM.id, 1],
                    [ProtectionType.ARMOUR_LIGHT.id, 1],
                    [Tools.WOODCARVER_TOOLS.id, 1],
                    [Tools.INSTRUMENT_HARMONICA.id, 1],
                ],
                avantages: [
                    [Skill.HISTORY.id, 'avantage.STONE_CUNNING'],
                ],
                modifiers: [],
                features: [
                    {
                        id: 'Clairvoyance (background)',
                        desc: 'Description de la compétence ...................................! .............................'
                    },
                    { id: 'Vision Nocturne' },
                    {
                        id: 'Endurance Naine',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Origine Erebor',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Robustesse Naine',
                        desc: 'Bonus de +1 point de vie par niveau (niveau 1 inclus).'
                    },
                    {
                        id: 'Connaissance de la pierre',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Terres connues',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Façons des Terres Sauvages',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Style de combat : Défense',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Vigilance naturelle',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Rumeur de la terre',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Ennemi de l\'Ombre : +2 dgt.',
                        desc: 'Description de la  ......................................! .............................'
                    },
                    {
                        id: 'Vertue: Corbeau de la Montagne',
                        desc: 'Description de la  ......................................! .............................'
                    }
                ],
                equipment: {
                    money: PennyValue.new(105, 5, 0),
                    weapons: [
                        {
                            id: 'Glaive Nain',
                            type: WeaponType.MARTIAL,
                            nbDice: 1,
                            diceType: Dice.d8,
                            dmgModif: 1,
                            hitModif: 1,
                            dmgType: DamageType.SLASHING,
                            ability: Ability.DEX,
                            properties: [WeaponProperty.FINESSE],
                            weight: 3,
                            cost: PennyValue.new(0, 30, 0)
                        },
                        Weapon.GREAT_BOW
                    ],
                    protections: [
                        {
                            enabled: true,
                            protection: {
                                id: 'Armure de Derri',
                                type: ProtectionType.ARMOUR_MEDIUM,
                                AC: 15,
                                DexModifMax: 2,
                                requiredStrength: null,
                                disavantagedStealth: false,
                                weight: 20,
                                cost: PennyValue.new(0, 0, 0),
                                description: 'Immunisé aux Critiques'
                            }
                        },
                        { enabled: true, protection: Protection.SHIELD },
                        { enabled: true, protection: Protection.FIGHTING_STYLE_DEFENCE },
                        { enabled: true, protection: Protection.DWARVEN_RESILIENCE }
                    ],
                    tools: [
                        Tools.INSTRUMENT_HARMONICA.id
                    ],
                    others: [
                        'Cape de fourrure de voyage',
                        'Nécessaires de voyage',
                        'Sac de couchage',
                        'Sac à dos',
                        'Bottes confortables',
                        'Bateau de marche stylisé',
                        'Pipe',
                        'Journal',
                    ]
                },
                about: {
                    title: 'Le marcheur',
                    origin: 'Montagnes Bleues (Exil)',
                    age: '52 ans',
                    weight: '80 kg',
                    size: '1 m 54',
                    // tslint:disable-next-line:max-line-length
                    look: 'Cheveux chataîn, barbe fournie et sourcils brousailleux, '
                        + 'son allure peu soignée et ses vêtements défraîchis par de '
                        + 'longs voyages peinent à dissimuler la qualité de '
                        + 'son équipement de très bonne facture. '
                        + 'Une subtile ordeur d\'herbe à pipe le suit en permance. '
                        + 'Ce nain parle d\'une voix grave et rocailleuse évoquant '
                        + 'les profondes cavernes de vieilles montagnes. '
                        + 'On peut souvent le voir occuper à scultper un morceau de bois, '
                        + 'ou encore sortir de sa poche un vieil harmonica qu\'il regarde '
                        + 'fixement perdu dans ses pensées, y jouant de temps à autre.',
                    relations: [
                        'Snorri, Borri et Har : Commerçants Nains voyageant vers Erebor',
                        'Gaïlavira (Rowan) : Cheftaine du village H-B sauvé',
                        'Gandalf : Croisé en voyage',
                        'Haldor et fils Belgo : Homme commerçant escorté d\'Esgaroth à Beorn.',
                        'Dody & Dindy Brandebouc : Tenanciers de l\'auberge de l\'Ouest',
                        'Beorn : Lettre de recommandation + services',
                    ],
                    titles: [
                        'Amis des elfes',
                        'Héros des Bois'
                    ],
                    patrons: [
                        'Radagast'
                    ],
                    sanctuaries: [
                        'Erebor',
                        'Auberge de l\'Est',
                        'Fort-Bois',
                        'Bourg-les-Bois',
                        'Castel-Pic',
                        'Rosgobel'
                    ],
                    adventures: [{
                        title: 'Histoire',
                        summary: 'Beli, le marcheur, est un nain peu locace dont les yeux dissimulés derrière d\'épais sourcils ' +
                        'reflètent un esprit vif plein de sagacité. Peu nombreuses sont les personnes connaissant ' +
                        'ses véritables motivations, et pourquoi ce nain parcourt sans cesse de dangereux sentiers par monts ' +
                        'et par vaux.' +
                        '\n\nCependant, Beli raconte bien volontier à qui veut l\'entendre les exploits de ses parents pendant ' +
                        'la fameuse <b>Guerre des Nains et des Orques</b> : ' +
                        '\n\n<i>" Là, les Montagnes de Brume se divisent, et entre leurs deux bras se trouve la vallée ombreuse ' +
                        'que nous ne pouvons oublier : <b>Azanulbizar</b>, le Val de Ruisselombre, non-loin des rives du lac de ' +
                        'Kheled-zâram où se dessina la couronne étoilée sur le front encore jeune de Durin l\'Immortel. ' +
                        '\n\nIl y a de cela plus d\'un siècle, ces lieux furent témoins d\'une bataille sans précédent entre ' +
                        'notre peuple et les orques des montagnes, point finale d\'une guerre qui vit, d\'année en année, la ' +
                        'quasi-exctinction de cette engence de l\'ombre, du plus haut des anciens pics nimbés de brume, jusqu\'aux ' +
                        'racines les plus profondes de ces montagnes. ' +
                        '\n\nCombien des nôtres tombèrent ce jour-là ? A vrai dire, nul ne le saura jamais vraiment, mais nombre ' +
                        'parmi les sept familles de mon peuple demeurèrent silencieux et ne soufflèrent mot une année durant, ' +
                        'portant le deuil de ces héros innombrables partis pour les Cavernes de Mandos, mais dont le sang lava ' +
                        'l\'honneur du roi Thrór.' +
                        '\n\nMa famille fut de celle-ci, et à l\'ombre des grands pins, au seuil des antiques portes de la grande ' +
                        'Khazad-dûm, mon père Dwali Trompe-Mort alluma le bûcher de son propre père Gruni, Oeil de Pierre, avant ' +
                        'de rejoindre en silence les gens du jeune seigneur Dáin, pourfendeur d\'Azog le profanateur et nouvel ' +
                        'héritier des Monts du Fer. ' +
                        '\n\nOui, ce seigneur-même qui aujourd\'hui reigne sur mon peuple à Erebor, la Montagne Solitaire, suite ' +
                        'à la <b>Bataille des Cinqs Armées</b> où mon Père tomba en voulant secourir le roi Thorin Ecu-de-Chêne. "</i>'
                    }, {
                        title: '1. En route vers Dale',
                        date: '2946, printemps',
                        summary: 'Beli, Avada et Valandor se rencontre dans un village homme des bois ' +
                            'alors que chacun, ayant entendu l\'appel de Barde, cherche à rejoindre Esgaroth. ' +
                            '\nSur la route ils aident des nains combattant des gobelins: Snorri, Borri et Har. ' +
                            'Soirée festive avec les nains : concours de fumée, chants et dégustation d\'un cochon.' +
                            '\n\nLe lendemain le groupe est capturé par des hommes des bois pour être jugé ' +
                            'pour le vol du cocho,n dans leur village, <b>Rowan</b>.' +
                            'L\'animal était un sacrifice pour une bête des bois qui attaque lors du jugement. ' +
                            'C\'est un gros warg que Beli voit dans ses rêves depuis quelques jours. ' +
                            'Le groupe se débarasse du loup et de ses sbires rapidement sauvant le village. ' +
                            '\n\nLa cheftaine du village <b>Gaïlavira</b> se montre reconnaissante. \n\nLes nains reste au villages ' +
                            'pour aider à la reconstruction et la fortification du village, alors que le groupe repart vers le nord. ' +
                            'Il croise <b>Gandalf</b> qui fait un bout de chemin avec eux...'
                    }, {
                        title: '2. Escorte à travers la Forêt Noire',
                        date: '2946, été',
                        summary: 'Sauvetage d\'un commerçant humain et son fils vers Esgaroth : <b>Baldor et Belgo</b>. ' +
                            'Le groupe décide de les escorter dans leur traversée de la Forêt Noire. ' +
                            'Une halte rapide est entreprise chez les elfes. Malgré la présence de Valandor, ' +
                            'le lieutenant de la garde ne se montre pas très coopératif. ' +
                            'Sur la route, ils croisent de nombreuses ruine d\'une ancienne civilisation humaine. ' +
                            '\n\nUne nuit un groupe d\'araignée capture le commerçant. Une opération de ' +
                            'sauvetage est alors improvisée, alors que Lohengrim le chien d\'Avada piste les araignées.' +
                            'Valandor le dégage furtivement d\'un nid dans une tour en ruine. ' +
                            'Un combat repousse quelques araignée et le groupe rentre au campement.' +
                            '\n\n Un jour ils tombent sur une cabane mystérieuse, marqué de symbols évoquant l\'Ennemi. ' +
                            'Le groupe y entre prudemment, et y trouve un homme des bois à moitié fou. ' +
                            'Après avoir réussi a le calmer. Ils comprennent qu\il s\'agit d\'un ancien héros disparu, Aldaric. ' +
                            'Il participa a une expédition pour retrouver le fils d\'un seigneur Homme des Bois il y a 30 ans. ' +
                            'Il possède la lame d\'une vieille hache de guerre runique qu\'il remet au aventurier. ' +
                            '\n\nQuelques jours plutard Avada entre dans un état second et courre dans les bois. ' +
                            'Il se précipite dans un précipice où se trouve une bête noire et tentaculaire. ' +
                            'Ni une ni deux, le groupe se jette sur la bête qui, dans un hurlement d\'agonie, ' +
                            'se transforme en une marre gélatineuse. ' +
                            '\n\nLes compagnons finirent leur traversée de la Forêt Noire, et s\'arrêtèrent à ' +
                            'l\'<b>Auberge de l\'Est</b>, établissement tenu par des hobbits nommés <b>Dody et Dundy Brandebouc</b>.'
                    }, {
                        title: 'Les Corbeaux de Durin',
                        date: '2946, été',
                        summary: '<b>Phase de communauté:</b> ' +
                            '\n\nLa monstruosité de la bête tentaculaire marqua fortement l\'esprit de Beli, ' +
                            'qui de retour à Erebor chercha à en savoir plus sur les sombres secrets que cachait ' +
                            'la Forêt Noire. Il fit alors la connaissance d\'un groupe de pisteurs nains mystérieux : ' +
                            '<b>Les Corbeaux de Durin</b>. \n\nCe groupe de nains fut fondé en 1980 3A, lorsque le peuple de Durin ' +
                            'dut quitter Khazad-dûm et s\'éparpilla à cause du Balrog. Il avait initialement pour rôle de maintenir ' +
                            'un lien entre les différentes colonies naines.' +
                            '\n\nAvec le temps, ce groupe de nain accumala de nombreuses connaissances sur régions montagneuses, ' +
                            'les pays alentours et des dangers qu\'ils cachaient.' +
                            '\n\nLes "Corbeaux" jouèrent un rôle centrale dans la préparation et la logistique lors ' +
                            'de la Guerre des Nains et des Orques en 2793-2799 3A, permettant de rassembler et coordonner ' +
                            'rapidement les différentes familles, et repérant les orques et leurs forteresses. ' +
                            '\n\nLe nom de ce groupe de pisteurs, vient du fait que bon nombre d\'entre eux est ' +
                            'lié d\'amitié avec des corbeaux de l\'ancienne race qui vivent autour du Mont Solitaire. ' +
                            'Doués de paroles et d\'une grande longévité, ces oiseaux sont souvent des compagnons avisés, ' +
                            'liés aux Nains depuis fort longtemps.' +
                            '\n\nBeli fut acceptés au sein des Corbeaux de Durin et initié à leur secrets. Depuis ce jour, ' +
                            'il piste la moindre trace de l\'Ennemi dans les contrées sauvages pour amasser des connaissances ' +
                            'utiles à la défense de son peuple, ses visions le poussant dans ce sens...'
                    }, {
                        title: '3. Hobbit en détresse',
                        date: '2946, automne',
                        summary: 'Au début de l\'été les compagnons, se retrouvèrent comme ils se l\'étaient promis, à <b>Fort-Bois</b>. ' +
                            'La hache du héros fou fut remise au conseil des anciens. Ceux-ci les en remercièrent grandement. ' +
                            'Ainsi les noms de Valandor, Avada et Beli commençèrent è se faire connaître. ' +
                            '\n\nLe groupe reparti vers le nord, et s\'arrêta à l\'<b>Auberge de l\'Est</b>. Il retrouvèrent ' +
                            '<b>Dody</b>. ' +
                            'Son frère <b>Dundy</b> avait semble-t-il disparu avec une caravane de marchandise. ' +
                            'Les aventuriers proposèrent leur service sachant que des orques rôdaient sur les routes de montagnes. ' +
                            '\n\nEt ils passèrent le péage Béornide du Vieux guet en direction du <b>Haut-Col</b>. ' +
                            'De nombreux témoignages faisaient état d\'une présence orque de plus en plus importante ces derniers temps. ' +
                            'Le long des chemins montagneux, les ruines d\'une ancienne civilisation émergeaient ça et là, ' +
                            'dernière trace d\'une gloire à jamais éteinte. En faisant halte dans une ruine, le groupe subit ' +
                            'l\'assaut du spectre d\'un guerrier antique et mirent fin à son tourment.' +
                            '\n\nLe lendemain, chemin faisant ils tombèrent sur un poney perdu non-loin du retse d\'une caravane ' +
                            'attaquée. Il suivirent des traces et trouvèrent deux guerriers Béornides et Dundy qui se cachaient ' +
                            'dans les ruines d\'une ancienne tour. Cela faisaient plusieurs jours que les goblins les harcelaient ' +
                            'et il étaient épuisés, à bout de force. Une bataille rapide s\'en suivit avec les orques qui furent ' +
                            'rapidement mis en déroute, mais qui capturèrent Dundy pour l\'enmené dans leur repère.' +
                            '\n\nUne expédition de sauvetage fut rapidement improvisé, et Dundy fut retrouvé à l\'entrée de leur grotte ' +
                            'préparant la cuisine. Valandor eu l\'idée d\'empoisonner le repas avec de la chélidoine. Les orques ' +
                            'affaiblis furent rapidement exterminé et les aventuriers ramenèrent Dundy et les béornides sains et sauf ' +
                            'dans la vallée...'
                    }, {
                        title: '4. Enquête chez les Béornides',
                        date: '2946, automne',
                        summary: 'De passage chez les Béornides les aventuriers découvrent une embarquations et deux cadavres ' +
                            'le long de l\'Anduin. Ils devinent rapidement qu\'il s\'agit de deux agents de <b>Béorn</b> tués par ' +
                            'des orques. Rapportant cela a Béorn, ils apprennnt qu\'il s\'agissait de deux héros de la ' +
                            'bataille des cinq armées. Ils devaient ramener un prisonnier du nom d\'<b>Oderik</b> du village ' +
                            'de <b>Stonyford</b>. ' +
                            '\n\nLes compagnons décidèrent de rentrer dans les bonnes grâces de Béorn et acceptèrent de l\'aider. ' +
                            'Il se rendirent à Stonyford et enquêtèrent sur l\'homme en question. Oderik semblait avoir voulu ' +
                            'défendre sa soeur adoptive maltraitée par son mari, et cela finit mal pour ce dernier. ' +
                            'D\'après les dires de sa soeur Oderik était parti dans la Vallée de l\'Aduin Ouest. ' +
                            '\n\nLà-bas ils tombèrent sur un campement de bandit qui préparaient une attaque contre Stonyford. ' +
                            'Oderik semblait être avec eux malgré-lui. Ils réussir à l\'exfiltrer, et celui-ci lui donnèrent ' +
                            'les détails de leurs plan qu\'ils communiquèrent à Béorn qui pu envoyer des troupes à temps pour ' +
                            ' la défense le village. ' +
                            'Lors de la bataille particulièrement sanglante qui s\'en suivi. Les bandits furent tous exterminé par ' +
                            'les Béornides. Et les compagnons médusés par les actes de barbaries dont ils avaient été témoins, ' +
                            'se rendirent au procès d\'Oderik qui fut acquitté grâce au talent d\'orateur de Valendor.'
                    }, ]
                }
            }
        );
    }

}
