export class Class {

    static SCHOLAR: Class = {
        id: 'class.SCHOLAR'
    };
    static SLAYER: Class = {
        id: 'class.SLAYER'
    };
    static TREASURE_HUNTER: Class = {
        id: 'class.TREASURE_HUNTER'
    };
    static WANDERER: Class = {
        id: 'class.WANDERER'
    };
    static WARDEN: Class = {
        id: 'class.WARDEN'
    };
    static WARRIOR: Class = {
        id: 'class.WARRIOR'
    };

    static ALL: Map<string, Class> = new Map([
        ['class.SCHOLAR', Class.SCHOLAR],
        ['class.SLAYER', Class.SLAYER],
        ['class.TREASURE_HUNTER', Class.TREASURE_HUNTER],
        ['class.WANDERER', Class.WANDERER],
        ['class.WARDEN', Class.WARDEN],
        ['class.WARRIOR', Class.WARRIOR]
    ]);

    id: string;

    static get(id: string): Class {
        return Class.ALL.get(id);
    }

    static values(): IterableIterator<Class> {
        return Class.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return Class.ALL.keys();
    }
}

export class ClassSpeciality {

    static SCHOLAR_MASTER_HEALER: ClassSpeciality = {
        id: 'class.speciality.SCHOLAR_MASTER_HEALER',
        clazz: Class.SCHOLAR
    };
    static SCHOLAR_MASTER_SCHOLAR: ClassSpeciality = {
        id: 'class.speciality.SCHOLAR_MASTER_SCHOLAR',
        clazz: Class.SCHOLAR
    };

    static SLAYER_THE_RIDER: ClassSpeciality = {
        id: 'class.speciality.SLAYER_THE_RIDER',
        clazz: Class.SLAYER
    };
    static SLAYER_FOE_HAMMER: ClassSpeciality = {
        id: 'class.speciality.SLAYER_FOE_HAMMER',
        clazz: Class.SLAYER
    };

    static TREASURE_HUNTER_AGENT: ClassSpeciality = {
        id: 'class.speciality.TREASURE_HUNTER_AGENT',
        clazz: Class.TREASURE_HUNTER
    };
    static TREASURE_HUNTER_BURGLAR: ClassSpeciality = {
        id: 'class.speciality.TREASURE_HUNTER_BURGLAR',
        clazz: Class.TREASURE_HUNTER
    };

    static WANDERER_HUNTER_OF_BEASTS: ClassSpeciality = {
        id: 'class.speciality.WANDERER_HUNTER_OF_BEASTS',
        clazz: Class.WANDERER
    };
    static WANDERER_HUNTER_OF_SHADOWS: ClassSpeciality = {
        id: 'class.speciality.WANDERER_HUNTER_OF_SHADOWS',
        clazz: Class.WANDERER
    };

    static WARDEN_COUNSELLOR: ClassSpeciality = {
        id: 'class.speciality.WARDEN_COUNSELLOR',
        clazz: Class.WARDEN
    };
    static WARDEN_HERALD: ClassSpeciality = {
        id: 'class.speciality.WARDEN_HERALD',
        clazz: Class.WARDEN
    };
    static WARDEN_BOUNDER: ClassSpeciality = {
        id: 'class.speciality.WARDEN_BOUNDER',
        clazz: Class.WARDEN
    };

    static WARRIOR_KNIGHT: ClassSpeciality = {
        id: 'class.speciality.WARRIOR_KNIGHT',
        clazz: Class.WARRIOR
    };
    static WARRIOR_WEAPON_MASTER: ClassSpeciality = {
        id: 'class.speciality.WARRIOR_WEAPON_MASTER',
        clazz: Class.WARRIOR
    };

    static ALL: Map<string, ClassSpeciality> = new Map([
        ['class.speciality.SCHOLAR_MASTER_HEALER', ClassSpeciality.SCHOLAR_MASTER_HEALER],
        ['class.speciality.SCHOLAR_MASTER_SCHOLAR', ClassSpeciality.SCHOLAR_MASTER_SCHOLAR],
        ['class.speciality.SLAYER_THE_RIDER', ClassSpeciality.SLAYER_THE_RIDER],
        ['class.speciality.SLAYER_FOE_HAMMER', ClassSpeciality.SLAYER_FOE_HAMMER],
        ['class.speciality.TREASURE_HUNTER_AGENT', ClassSpeciality.TREASURE_HUNTER_AGENT],
        ['class.speciality.TREASURE_HUNTER_BURGLAR', ClassSpeciality.TREASURE_HUNTER_BURGLAR],
        ['class.speciality.WANDERER_HUNTER_OF_BEASTS', ClassSpeciality.WANDERER_HUNTER_OF_BEASTS],
        ['class.speciality.WANDERER_HUNTER_OF_SHADOWS', ClassSpeciality.WANDERER_HUNTER_OF_SHADOWS],
        ['class.speciality.WARDEN_COUNSELLOR', ClassSpeciality.WARDEN_COUNSELLOR],
        ['class.speciality.WARDEN_HERALD', ClassSpeciality.WARDEN_HERALD],
        ['class.speciality.WARDEN_BOUNDER', ClassSpeciality.WARDEN_BOUNDER],
        ['class.speciality.WARRIOR_KNIGHT', ClassSpeciality.WARRIOR_KNIGHT],
        ['class.speciality.WARRIOR_WEAPON_MASTER', ClassSpeciality.WARRIOR_WEAPON_MASTER]
    ]);

    id: string;
    clazz: Class;

    static get(id: string): ClassSpeciality {
        return ClassSpeciality.ALL.get(id);
    }

    static values(): IterableIterator<ClassSpeciality> {
        return ClassSpeciality.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return ClassSpeciality.ALL.keys();
    }
}
