export enum BgFeature {
    INSEPARABLE,
    DARK_FOREBODING,
    INFAMOUS,
    SIGIL_OF_YOUR_MASTER,
    NOBLE_BEARING,
    FOREKNOWLEDGE,
    TROUBLING_TO_THE_WISE,
    WEATHER_LORE,
    FOLK_HEALER,
    AIR_OF_MAGIC,
    MIGHTY_OATH,
    PATHETIC_AND_BEDRAGGLED,
    LORE_OF_THE_LOST,
    SEA_DREAMS,
    SEASONED_CONNECTIONS
}
