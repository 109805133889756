export class Ability {

    static STR: Ability = {
        id: 'ability.STR'
    };
    static DEX: Ability = {
        id: 'ability.DEX'
    };
    static CON: Ability = {
        id: 'ability.CON'
    };
    static INT: Ability = {
        id: 'ability.INT'
    };
    static WIS: Ability = {
        id: 'ability.WIS'
    };
    static CHA: Ability = {
        id: 'ability.CHA'
    };
    static SHA: Ability = {
        id: 'ability.SHA'
    };

    static ALL: Map<string, Ability> = new Map<string, Ability>([
        ['ability.STR', Ability.STR],
        ['ability.DEX', Ability.DEX],
        ['ability.CON', Ability.CON],
        ['ability.INT', Ability.INT],
        ['ability.WIS', Ability.WIS],
        ['ability.CHA', Ability.CHA],
        ['ability.SHA', Ability.SHA],
    ]);

    id: string;

    static get(id: string): Ability {
        return Ability.ALL.get(name);
    }

    static values(): IterableIterator<Ability> {
        return Ability.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return Ability.ALL.keys();
    }

}
