import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CharacterInfoComponent } from './pages/character-info/character-info.component';
import { DictionnaryPipe } from './pipes/dictionnary';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LineWrapPipe } from './pipes/line-wrap';
import { RouterModule } from '@angular/router';
import { routingModule } from './app.routing';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatTabsModule,
  MatFormFieldModule,
  MatStepperModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatInputModule
} from '@angular/material';
import { AntikaComponent } from './components/antika/antika.component';
import { SdaComponent, FeatureDialog, RiddleDialog } from './components/sda/sda.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

@NgModule({
  declarations: [
    AppComponent,
    FeatureDialog,
    RiddleDialog,
    CharacterInfoComponent,
    DictionnaryPipe,
    LineWrapPipe,
    AntikaComponent,
    SdaComponent
  ],
  imports: [
    DeferLoadModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTabsModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatInputModule,
    MatExpansionModule,
    DragDropModule,
    AngularFontAwesomeModule
  ],
  exports: [MatButtonModule, MatCheckboxModule],
  providers: [RouterModule],
  bootstrap: [AppComponent],
  entryComponents : [
    FeatureDialog, RiddleDialog
  ]
})
export class AppModule { }
