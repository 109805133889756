export class DamageType {

    static BLUDGEONING: DamageType = {id: 'weapon.damage.type.BLUDGEONING'};
    static PIERCING: DamageType = {id: 'weapon.damage.type.PIERCING'};
    static SLASHING: DamageType = {id: 'weapon.damage.type.SLASHING'};
    static FIRE: DamageType = {id: 'weapon.damage.type.FIRE'};
    static WATER: DamageType = {id: 'weapon.damage.type.WATER'};
    static EARTH: DamageType = {id: 'weapon.damage.type.EARTH'};
    static WIND: DamageType = {id: 'weapon.damage.type.WIND'};
    static SHADOW: DamageType = {id: 'weapon.damage.type.SHADOW'};
    static LIGHT: DamageType = {id: 'weapon.damage.type.LIGHT'};
    static MAGIC: DamageType = {id: 'weapon.damage.type.MAGIC'};
    static DIVINE: DamageType = {id: 'weapon.damage.type.DIVINE'};

    id: string;
}
