import { Injectable } from '@angular/core';
import { NAMESPACE_URIS } from '@angular/platform-browser/src/dom/dom_renderer';
import { identifierModuleUrl } from '@angular/compiler';


@Injectable()
export class DivinationService {

    static NAMES: Map<Number, string> = new Map([
        [1, 'Abeille'],
        [2, 'Abime'],
        [3, 'Agneau'],
        [4, 'Aigle'],
        [5, 'Alouette'],
        [6, 'Ane'],
        [7, 'Arme'],
        [8, 'Bateau'],
        [9, 'Bâton'],
        [10, 'Bleu'],
        [11, 'Boiteux/Bequille'],
        [12, 'Cendre'],
        [13, 'Cercle / Anneau'],
        [14, 'Chêne'],
        [15, 'Chimère'],
        [16, 'Coq'],
        [17, 'Cocquillage/Conque'],
        [18, 'Corbeau'],
        [19, 'Couteau'],
        [20, 'Croisement'],
        [21, 'Danse'],
        [22, 'Dragon'],
        [23, 'Eau / Mer'],
        [24, 'Flamme / Feu'],
        [25, 'Grenade'],
        [26, 'Grotte'],
        [27, 'Insecte'],
        [28, 'Lapin / Lièvre'],
        [29, 'Lion'],
        [30, 'Mort'],
        [31, 'Noeud'],
        [32, 'Noir'],
        [33, 'Oeuf'],
        [34, 'Olivier'],
        [35, 'Or'],
        [36, 'Ours'],
        [37, 'Pavot'],
        [38, 'Perle'],
        [39, 'Pont'],
        [40, 'Pourpre'],
        [41, 'Puit'],
        [42, 'Quenouille'],
        [43, 'Rouge'],
        [44, 'Rubis'],
        [45, 'Ruche'],
        [46, 'Sacrifice'],
        [47, 'Sceptre'],
        [48, 'Serpent'],
        [49, 'Temple'],
        [50, 'Tombeau'],
        [51, 'Tour'],
        [52, 'Vent'],
        [53, 'Vert'],
        [54, 'Vêtement'],
        [55, 'Vierge'],
        [56, 'Vin'],
        [57, 'Nudité'],
        [58, 'Myrrhe'],
        [59, 'Rose'],
        [60, 'Colombe'],
        [61, 'Bouc'],
        [62, 'Soleil'],
        [63, 'Lyre'],
        [64, 'Arc'],
        [65, 'Flûte'],
        [66, 'Laurier'],
        [67, 'Dauphin'],
        [68, 'Signe'],
        [69, 'loup'],
        [70, 'Epervier'],
        [71, 'Lance'],
        [72, 'Casque'],
        [73, 'Bouclier'],
        [74, 'Vautour'],
        [75, 'Pivert'],
        [76, 'Chien'],
        [77, 'Lune'],
        [78, 'Myrte'],
        [79, 'Biche'],
        [80, 'Chouette'],
        [81, 'Jument'],
        [82, 'Blé'],
        [83, 'Faucille'],
        [84, 'Truie'],
        [85, 'Tourterelle'],
        [86, 'Bélier'],
        [87, 'Vigne / Raison'],
        [88, 'Pin'],
        [89, 'Figuier'],
        [90, 'Thyrsus'],
        [91, 'Panthère'],
        [92, 'Taureau'],
        [93, 'Cypré'],
        [94, 'Cerf'],
        [95, 'Cerbère'],
        [96, 'Chèvre'],
        [97, 'Mage'],
        [98, 'Marteau'],
        [99, 'Enclume'],
        [100, 'Diadème'],
        [101, 'Lys'],
        [102, 'Paon'],
        [103, 'Génisse'],
        [104, 'Caducé'],
        [105, 'Sandale'],
        [106, 'Bourse'],
        [107, 'Pétase'],
        [108, 'Tortue'],
        [109, 'Tribu'],
        [110, 'Cheval'],
        [111, 'Hippocampe'],
        [112, 'Foudre'],
        [113, 'Torche'],
        [114, 'Palme'],
        [115, 'Oie'],
        [116, 'Homard'],
        [117, 'Phallus'],
        [118, 'Phoque'],
        [119, 'Blanc / Ivoire'],
        [120, 'Léviathan'],
        [121, 'Fouet'],
        [122, 'Flêche'],
        [123, 'Arc-en-ciel'],
        [124, 'Balance'],
        [125, 'Hache'],
        [126, 'Femme ailée'],
        [127, 'Sablier'],
        [128, 'Epée'],
        [129, 'Ailes'],
        [130, 'Alpha'],
        [131, 'Oméga'],
        [132, 'Araignée'],
        [133, 'Boite'],
        [134, 'Boue'],
        [135, 'Brouillard'],
        [136, 'Omphale'],
        [137, 'Orange'],
        [138, 'Jaune'],
        [139, 'Marron'],
        [140, 'Cyclope'],
        [141, 'Géant'],
        [142, 'L\'île'],
        [143, 'Labyrinthe'],
        [144, 'Masque'],
        [145, 'Montagne'],
        [146, 'Oeil'],
        [147, 'Sang'],
    ]);

    constructor() {
    }

    getName(id: Number) {
        return DivinationService.NAMES.get(id);
    }

    addRandom(perso: any): Number {
        if (perso.divinations.length >= 6) {
            return null;
        }

        let id = (Math.floor(Math.random() * 147) + 1) % 147;
        while (perso.divinations.includes(id)) {
            id = (Math.floor(Math.random() * 147) + 1) % 147;
        }
        perso.divinations[perso.divinations.length] = id;
        this.saveDivinations(perso);
        return id;
    }

    saveDivinations(perso: any) {
      localStorage.setItem('divinations-' + perso.nickname, JSON.stringify(perso.divinations));
    }
}
