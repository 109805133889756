export enum PennyType {
    GOLD, SILVER, COPPER
}

export class PennyValue {
    coins: number;

    static new(gold: number, silver: number, copper: number): PennyValue {
        const o = new PennyValue();
        o.coins = gold * 240 + silver * 12 + copper;
        return o;
    }

    static generateFromType(type: PennyType, value: number): PennyValue {
        const o = new PennyValue();
        o.coins = this.toCoins(type, value);
        return o;
    }

    static toCoins(type: PennyType, value: number): number {
        switch (type) {
            case PennyType.GOLD:
                return value * 240;
            case PennyType.SILVER:
                return value * 12;
            default:
                return value;
        }
    }

    format(): string {
        const gold = Math.trunc(this.coins / 240);
        const silvers = Math.trunc((this.coins % 240) / 12);
        const coppers = (this.coins % 240) % 12;
        return gold + ' g  ' + silvers + ' s  ' + coppers + ' c';
    }
}
