// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  lang: 'fr',
  names: {
    'fr': {
      // misc.
      'misc.weapons': 'Armes',
      'misc.damage': 'Dégâts',
      'misc.type': 'Type',
      'misc.modif': 'Modif.',
      'misc.others': 'Autres',
      'misc.protections': 'Protections',
      'misc.tools': 'Outils',
      'misc.proficiency.bonus': 'Bonus de Maîtrise',
      'misc.initiative': 'Initiative',
      'misc.speed': 'Vitesse',
      'misc.perception.passive': 'Perc. Passive',
      'misc.shadow': 'OMBRE',
      'misc.equipment': 'Equipement',
      'misc.talents.virtues.etc': 'Capacités, Vertues et Autres',
      'misc.proficiencies': 'Maîtrises',
      'misc.HP': 'PV',
      'misc.AC': 'CA',
      'misc.money': 'Pièces',
      'misc.relations': 'Relations',
      'misc.sanctuaries': 'Sanctuaires',
      'misc.patrons': 'Garants',
      'misc.titles': 'Titres',
      'misc.level': 'niv.',

      // cultures
      'culture.BARDING': 'Valien',
      'culture.BEORNING': 'Béornide',
      'culture.DUNEDAIN': 'Dunedain',
      'culture.DWARF_EREBOR': 'Nain d\'Erebor',
      'culture.DWARF_IRON_HILLS': 'Nain des Monts de Fer',
      'culture.ELF_MIRKWOOD': 'Elfe Sylvain',
      'culture.HOBBIT_FALLOWHIDE': 'Hobbit (Pâle)',
      'culture.HOBBIT_HARFOOT': 'Hobbit (Pieds velus)',
      'culture.HOBBIT_STOOR': 'Hobbit (Fort)', // FIXME': trad.
      'culture.MEN_OF_BREE': 'Homme de Bree',
      'culture.MEN_OF_MINAS_TIRITH': 'Homme de Minas Tirith',
      'culture.MEN_OF_THE_LAKE': 'Homme du Lac',
      'culture.RIDERS_OF_ROHAN': 'Cavalier du Rohan',
      'culture.WOODMEN_OF_WILDERLAND': 'Homme des Bois',

      // classes
      'class.SCHOLAR': 'Erudit',
      'class.SLAYER': 'Vengeur',
      'class.TREASURE_HUNTER': 'Chasseur de Trésor',
      'class.WANDERER': 'Vagabond',
      'class.WARDEN': 'Protecteur',
      'class.WARRIOR': 'Frère d\'armes',

      // classes : specialité
      'class.speciality.SCHOLAR_MASTER_HEALER': 'Maître Guérisseur',
      'class.speciality.SCHOLAR_MASTER_SCHOLAR': 'Maître Erudit',
      'class.speciality.SLAYER_THE_RIDER': 'Cavalier',
      'class.speciality.SLAYER_FOE_HAMMER': 'Pourfendeur',
      'class.speciality.TREASURE_HUNTER_AGENT': 'Espion',
      'class.speciality.TREASURE_HUNTER_BURGLAR': 'Cambrioleur',
      'class.speciality.WANDERER_HUNTER_OF_BEASTS': 'Chasseur de Bêtes',
      'class.speciality.WANDERER_HUNTER_OF_SHADOWS': 'Chasseur d\'Ombres',
      'class.speciality.WARDEN_COUNSELLOR': 'Conseiller',
      'class.speciality.WARDEN_HERALD': 'Hérault',
      'class.speciality.WARDEN_BOUNDER': 'Frontalier',
      'class.speciality.WARRIOR_KNIGHT': 'Chevalier',
      'class.speciality.WARRIOR_WEAPON_MASTER': 'Maître d\'Armes',

      // skills
      'skills.title': 'Skills',
      'skill.ACROBATICS': 'Acrobaties',
      'skill.ANIMAL_HANDLING': 'Dressage',
      'skill.ATHLETICS': 'Athlétisme',
      'skill.DECEPTION': 'Tromperie',
      'skill.HISTORY': 'Histoire',
      'skill.INSIGHT': 'Perspicacité',
      'skill.INTIMIDATION': 'Intimidation',
      'skill.INVESTIGATION': 'Investigation',
      'skill.LORE': 'Mystères',
      'skill.MEDICINE': 'Médecine',
      'skill.NATURE': 'Nature',
      'skill.PERCEPTION': 'Perception',
      'skill.PERFORMANCE': 'Représentation',
      'skill.PERSUASION': 'Persuasion',
      'skill.RIDDLE': 'Enigme',
      'skill.SHADOW_LORE': 'Consc. Ombre',
      'skill.SLEIGHT_OF_HAND': 'Escamotage',
      'skill.STEALTH': 'Discrétion',
      'skill.SURVIVAL': 'Survie',
      'skill.TRADITIONS': 'Traditions',

      // abilities
      'abilities.title': 'Attributs',
      'ability.STR': 'Force',
      'ability.DEX': 'Dextérite',
      'ability.CON': 'Constitution',
      'ability.INT': 'Intelligence',
      'ability.WIS': 'Sagesse',
      'ability.CHA': 'Charisme',
      'ability.SHA': 'Ombre',
      'ability.STR.short': 'FOR',
      'ability.DEX.short': 'DEX',
      'ability.CON.short': 'CON',
      'ability.INT.short': 'INT',
      'ability.WIS.short': 'SAG',
      'ability.CHA.short': 'CHA',
      'ability.SHA.short': 'OMB',

      // weapons
      'weapon.CLUB': 'Gourdin',
      'weapon.DAGGER': 'Dague',
      'weapon.GREAT_CLUB': 'Grand Gourdin',
      'weapon.HAND_AXE': 'Petite Hache',
      'weapon.HAMMER': 'Marteau',
      'weapon.MACE': 'Masse',
      'weapon.STAFF': 'Bâton',
      'weapon.SPEAR': 'Lance',
      'weapon.SHORT_BOW': 'Arc Court',
      'weapon.SLING': 'Fronde',
      'weapon.AXE': 'Hache',
      'weapon.GREAT_AXE': 'Grande Hache',
      'weapon.GREAT_SPEAR': 'Grande Lance',
      'weapon.HEAVY_SCIMITAR': 'Scimeterre',
      'weapon.LONG_SWORD': 'Epée Longue',
      'weapon.MATTOCK': 'Pioche',
      'weapon.SCIMITAR': 'Scimeterre',
      'weapon.SHORT_SWORD': 'Epée Courte',
      'weapon.BROADSWORD': 'Glaive',
      'weapon.WARHAMMER': 'Marteau de Guerre',
      'weapon.GREAT_BOW': 'Arc Long',

      // weapon types
      'weapon.type.SIMPLE': 'Armes courantes',
      'weapon.type.MARTIAL': 'Armes de guerre',

      // weapon properties
      'weapon.property.DWARF_FORGED': 'Manufacture Naine',
      'weapon.property.LIGHT': 'Légère',
      'weapon.property.HEAVY': 'Lourde',
      'weapon.property.FINESSE': 'Finesse',
      'weapon.property.REACH': 'Allonge',
      'weapon.property.THROWN_20_60': 'Lancer (20/60)',
      'weapon.property.TWO_HANDED': 'A Deux Mains',
      'weapon.property.VERSATILE_1D4': 'Versatile (1d4)',
      'weapon.property.VERSATILE_1D6': 'Versatile (1d6)',
      'weapon.property.VERSATILE_1D8': 'Versatile (1d8)',
      'weapon.property.VERSATILE_1D10': 'Versatile (1d10)',
      'weapon.property.VERSATILE_1D12': 'Versatile (1d12)',
      'weapon.property.AMMUNITION_30_120': 'A Projectile (30/120)',
      'weapon.property.AMMUNITION_80_320': 'A Projectile (80/320)',
      'weapon.property.AMMUNITION_150_600': 'A Projectile (150/600)',

      // weapon damage types
      'weapon.damage.type.BLUDGEONING': 'Contendant',
      'weapon.damage.type.PIERCING': 'Perforant',
      'weapon.damage.type.SLASHING': 'Tranchant',
      'weapon.damage.type.FIRE': 'Feu',
      'weapon.damage.type.WATER': 'Eau',
      'weapon.damage.type.EARTH': 'Terre',
      'weapon.damage.type.WIND': 'Air',
      'weapon.damage.type.SHADOW': 'Ombre',
      'weapon.damage.type.LIGHT': 'Lumière',
      'weapon.damage.type.MAGIC': 'Magique',
      'weapon.damage.type.DIVINE': 'Divin',

      // protection types
      'protection.type.ARMOUR_LIGHT': 'Armures légères',
      'protection.type.ARMOUR_MEDIUM': 'Armures intermédiaires',
      'protection.type.ARMOUR_HEAVY': 'Armures lourdes',
      'protection.type.SHIELD': 'Boucliers',
      'protection.type.OTHERS': 'Protections diverses',

      // protections
      'protection.armour.LEATHER_JERKIN': 'Pourpoint de cuir',
      'protection.armour.LEATHER_JERKIN.desc': '+Dex',
      'protection.armour.LEATHER_CORSLET': 'Corselet de cuir',
      'protection.armour.LEATHER_CORSLET.desc': '+Dex',
      'protection.armour.HIDE': 'Armure de peau',
      'protection.armour.HIDE.desc': '+2 DEX max',
      'protection.armour.CORSLET_OF_MAIL': 'Corselet de mailles',
      'protection.armour.CORSLET_OF_MAIL.desc': '+2 DEX max',
      'protection.armour.SCALE_HAUBERK': 'Haubert d\'écailles',
      'protection.armour.SCALE_HAUBERK.desc': '+2 DEX max ; Discret. désavtg.',
      'protection.armour.RING_MAIL': 'Cotte d\'anneaux',
      'protection.armour.RING_MAIL.desc': 'Discret. désavtg.',
      'protection.armour.HEAVY_MAIL': 'Haubert de mailles',
      'protection.armour.HEAVY_MAIL.desc': 'FOR 13 ; Discret. désavtg.',
      'protection.shield.SHIELD': 'Bouclier',
      'protection.shield.SHIELD.desc': '-',
      'protection.shield.GREAT_SHIELD': 'Grand bouclier',
      'protection.shield.GREAT_SHIELD.desc': 'FOR 13 ; Discret. désavtg.',
      'protection.others.DWARVEN_RESILIENCE': 'Résilience Naine',
      'protection.others.DWARVEN_RESILIENCE.desc': 'Résist. + Avtg. vs Poison',
      'protection.others.FIGHTING_STYLE_DEFENCE': 'Style Cbt. Défensif',
      'protection.others.FIGHTING_STYLE_DEFENCE.desc': '-',
      'protection.others.SLAYER_BODY': 'Déf. sans armure',
      'protection.others.SLAYER_BODY.desc': 'CA = 10 + DEX + CON',

      // Outils
      'tools.BREWERS_SUPPLIES': 'Matériel de brasseur',
      'tools.CALLIGRAPHERS_SUPPLIES': 'Matériel de calligraphie',
      'tools.CARPENTERS_TOOLS': 'Outils de charpentier',
      'tools.WOODCARVER_TOOLS': 'Outils de menuisier',
      'tools.CARTOGRAPHERS_TOOLS': 'Outils de cartographe',
      'tools.COBBLERS_TOOLS': 'Outils de cordonnier',
      'tools.COOKS_UTENSILS': 'Ustensiles de cuisinier',
      'tools.DICE_SET': 'Dés',
      'tools.GAMING_SET': 'Boîte de jeux',
      'tools.GLASSBLOWERS_TOOLS': 'Outils de souffleur de verre',
      'tools.HERBALISM_KIT': 'Matériel d\'herboriste',
      'tools.JEWELLERS_TOOLS': 'Outils de joallier',
      'tools.LEATHERWORKERS_TOOLS': 'Outils de tanneur',
      'tools.MASONS_TOOLS': 'Outils de maçon',
      'tools.PAINTERS_SUPPLIES': 'Matériel de peintre',
      'tools.POTTERS_TOOLS': 'Outils de potier',
      'tools.SMITHS_TOOLS': 'Outils de forgeron',
      'tools.TINKERS_TOOLS': 'Outils de bricoleur',
      'tools.WEAVERS_TOOLS': 'Outils de tisserand',
      'tools.PIPE_SMOKING_SUPPLIES': 'Nécessaire d\'herbe à pipe',
      'tools.POISON_TOOLS': 'Outils d\'empoisonneur',
      'tools.THIEVES_TOOLS': 'Outils de voleur',
      'tools.INSTRUMENT_DRUM': 'Instrument (Tambour)',
      'tools.INSTRUMENT_FIDDLE': 'Instrument (Violon)',
      'tools.INSTRUMENT_FLUTE': 'Instrument (Flûte)',
      'tools.INSTRUMENT_LUTE': 'Instrument (Luth)',
      'tools.INSTRUMENT_LYRE': 'Instrument (Lyre)',
      'tools.INSTRUMENT_HORN': 'Instrument (Cor)',
      'tools.INSTRUMENT_VIOL': 'Instrument (Viol)',
      'tools.INSTRUMENT_HARMONICA': 'Instrument (Harmonica)',
      'tools.INSTRUMENT_OTHER': 'Instrument (Autre)',

      // avantages
      'avantage.STONE_CUNNING': 'Construction en Pierre : + bonus de maîtrise x 2',
      'avantage.WOODEN_STEALTH': 'Avantage en forêt',

      // features
      'feature.DWARVEN_TOUGHNESS': 'Robustesse Naine',
      'feature.DWARVEN_TOUGHNESS.desc': '+1 PV/Niveau',
    },
    'en': {
      // misc.
      'misc.weapons': 'Weapon',
      'misc.damage': 'Damage',
      'misc.type': 'Type',
      'misc.modif': 'Modif.',
      'misc.others': 'Others',
      'misc.protections': 'Protections',
      'misc.tools': 'Tools',
      'misc.proficiency.bonus': 'Bonus of Proficiency',
      'misc.initiative': 'Initiative',
      'misc.speed': 'Speed',
      'misc.perception.passive': 'Passive Perc.',
      'misc.shadow': 'SHADOW',
      'misc.equipment': 'Equipment',
      'misc.talents.virtues.etc': 'Features, Virtues and Others',
      'misc.proficiencies': 'Mastering',
      'misc.HP': 'HP',
      'misc.AC': 'AC',
      'misc.money': 'Money',
      'misc.relations': 'Relations',
      'misc.sanctuaries': 'Sanctuaries',
      'misc.patrons': 'Patrons',
      'misc.titles': 'Titles',
      'misc.level': 'lvl.',

      // cultures
      'culture.BARDING': 'Barding',
      'culture.BEORNING': 'Beorning',
      'culture.DUNEDAIN': 'Dunedain',
      'culture.DWARF_EREBOR': 'Dwarf of Erebor',
      'culture.DWARF_IRON_HILLS': 'Dwarf of Iron Hills',
      'culture.ELF_MIRKWOOD': 'Elf of Mirkwood',
      'culture.HOBBIT_FALLOWHIDE': 'Hobbit (Fallowhide)',
      'culture.HOBBIT_HARFOOT': 'Hobbit (Harfoot)',
      'culture.HOBBIT_STOOR': 'Hobbit (Stoor)', // FIXME': trad.
      'culture.MEN_OF_BREE': 'Man of Bree',
      'culture.MEN_OF_MINAS_TIRITH': 'Man of Minas Tirith',
      'culture.MEN_OF_THE_LAKE': 'Man of the Lake',
      'culture.RIDERS_OF_ROHAN': 'Rider of Rohan',
      'culture.WOODMEN_OF_WILDERLAND': 'Woodman of Wilderland',

      // classes
      'class.SCHOLAR': 'Scholar',
      'class.SLAYER': 'Slayer',
      'class.TREASURE_HUNTER': 'Treasure Hunter',
      'class.WANDERER': 'Wanderer',
      'class.WARDEN': 'Warden',
      'class.WARRIOR': 'Warrior',

      // classes : specialité
      'class.speciality.SCHOLAR_MASTER_HEALER': 'Master Healer',
      'class.speciality.SCHOLAR_MASTER_SCHOLAR': 'Master Scholar',
      'class.speciality.SLAYER_THE_RIDER': 'Rider',
      'class.speciality.SLAYER_FOE_HAMMER': 'Foe Hammer',
      'class.speciality.TREASURE_HUNTER_AGENT': 'Agent',
      'class.speciality.TREASURE_HUNTER_BURGLAR': 'Burglar',
      'class.speciality.WANDERER_HUNTER_OF_BEASTS': 'Hunter of Beasts',
      'class.speciality.WANDERER_HUNTER_OF_SHADOWS': 'Hunter of Shadows',
      'class.speciality.WARDEN_COUNSELLOR': 'Counsellor',
      'class.speciality.WARDEN_HERALD': 'Herald',
      'class.speciality.WARDEN_BOUNDER': 'Bounder',
      'class.speciality.WARRIOR_KNIGHT': 'Knight',
      'class.speciality.WARRIOR_WEAPON_MASTER': 'Weapon Master',

      // skills
      'skills.title': 'Skills',
      'skill.ACROBATICS': 'Acrobatics',
      'skill.ANIMAL_HANDLING': 'Animal Handling',
      'skill.ATHLETICS': 'Athletics',
      'skill.DECEPTION': 'Deception',
      'skill.HISTORY': 'History',
      'skill.INSIGHT': 'Insight',
      'skill.INTIMIDATION': 'Intimidation',
      'skill.INVESTIGATION': 'Investigation',
      'skill.LORE': 'Lore',
      'skill.MEDICINE': 'Medicine',
      'skill.NATURE': 'Nature',
      'skill.PERCEPTION': 'Perception',
      'skill.PERFORMANCE': 'Performance',
      'skill.PERSUASION': 'Persuasion',
      'skill.RIDDLE': 'Riddle',
      'skill.SHADOW_LORE': 'Shadow Lore',
      'skill.SLEIGHT_OF_HAND': 'Sleight of hand',
      'skill.STEALTH': 'Stealth',
      'skill.SURVIVAL': 'Survival',
      'skill.TRADITIONS': 'Traditions',

      // abilities
      'abilities.title': 'Abilities',
      'ability.STR': 'Strength',
      'ability.DEX': 'Dexterity',
      'ability.CON': 'Constitution',
      'ability.INT': 'Intelligence',
      'ability.WIS': 'Wisdom',
      'ability.CHA': 'Charisma',
      'ability.SHA': 'Shadow',
      'ability.STR.short': 'STR',
      'ability.DEX.short': 'DEX',
      'ability.CON.short': 'CON',
      'ability.INT.short': 'INT',
      'ability.WIS.short': 'WIS',
      'ability.CHA.short': 'CHA',
      'ability.SHA.short': 'SHA',

      // weapons
      'weapon.CLUB': 'Club',
      'weapon.DAGGER': 'Dagger',
      'weapon.GREAT_CLUB': 'Great club',
      'weapon.HAND_AXE': 'Hand axe',
      'weapon.HAMMER': 'Hammer',
      'weapon.MACE': 'Mace',
      'weapon.STAFF': 'Staff',
      'weapon.SPEAR': 'Spear',
      'weapon.SHORT_BOW': 'Short bow',
      'weapon.SLING': 'Sling',
      'weapon.AXE': 'Axe',
      'weapon.GREAT_AXE': 'Great axe',
      'weapon.GREAT_SPEAR': 'Great spear',
      'weapon.HEAVY_SCIMITAR': 'Heavy scimitar',
      'weapon.LONG_SWORD': 'Long sword',
      'weapon.MATTOCK': 'Mattock',
      'weapon.SCIMITAR': 'Scimitar',
      'weapon.SHORT_SWORD': 'Short sword',
      'weapon.BROADSWORD': 'Broadsword',
      'weapon.WARHAMMER': 'Warhammer',
      'weapon.GREAT_BOW': 'Great bow',

      // weapon types
      'weapon.type.SIMPLE': 'Simple weapon',
      'weapon.type.MARTIAL': 'Martial weapon',

      // weapon properties
      'weapon.property.DWARF_FORGED': 'Dwarf forged',
      'weapon.property.LIGHT': 'Light',
      'weapon.property.HEAVY': 'Heavy',
      'weapon.property.FINESSE': 'Finesse',
      'weapon.property.REACH': 'Reach',
      'weapon.property.THROWN_20_60': 'Thrown (20/60)',
      'weapon.property.TWO_HANDED': 'Two-handed',
      'weapon.property.VERSATILE_1D4': 'Versatile (1d4)',
      'weapon.property.VERSATILE_1D6': 'Versatile (1d6)',
      'weapon.property.VERSATILE_1D8': 'Versatile (1d8)',
      'weapon.property.VERSATILE_1D10': 'Versatile (1d10)',
      'weapon.property.VERSATILE_1D12': 'Versatile (1d12)',
      'weapon.property.AMMUNITION_30_120': 'Ammunition (30/120)',
      'weapon.property.AMMUNITION_80_320': 'Ammunition (80/320)',
      'weapon.property.AMMUNITION_150_600': 'Ammunition (150/600)',

      // weapon damage types
      'weapon.damage.type.BLUDGEONING': 'Bludgeoning',
      'weapon.damage.type.PIERCING': 'Piercing',
      'weapon.damage.type.SLASHING': 'Slashing',
      'weapon.damage.type.FIRE': 'Fire',
      'weapon.damage.type.WATER': 'Water',
      'weapon.damage.type.EARTH': 'Earth',
      'weapon.damage.type.WIND': 'Wind',
      'weapon.damage.type.SHADOW': 'Shadow',
      'weapon.damage.type.LIGHT': 'Light',
      'weapon.damage.type.MAGIC': 'Magic',
      'weapon.damage.type.DIVINE': 'Divine',

      // protection types
      'protection.type.ARMOUR_LIGHT': 'Armour light',
      'protection.type.ARMOUR_MEDIUM': 'Armour medium',
      'protection.type.ARMOUR_HEAVY': 'Armour heavy',
      'protection.type.SHIELD': 'Shield',
      'protection.type.OTHERS': 'Other Protections',

      // protections
      'protection.armour.LEATHER_JERKIN': 'Leather Jerkin',
      'protection.armour.LEATHER_JERKIN.desc': '+Dex',
      'protection.armour.LEATHER_CORSLET': 'Leather corslet',
      'protection.armour.LEATHER_CORSLET.desc': '+Dex',
      'protection.armour.HIDE': 'Hide',
      'protection.armour.HIDE.desc': '+2 DEX max',
      'protection.armour.CORSLET_OF_MAIL': 'Corslet of mail',
      'protection.armour.CORSLET_OF_MAIL.desc': '+2 DEX max',
      'protection.armour.SCALE_HAUBERK': 'Scale hauberk',
      'protection.armour.SCALE_HAUBERK.desc': '+2 DEX max ; Steath disavtg.',
      'protection.armour.RING_MAIL': 'Ring mail',
      'protection.armour.RING_MAIL.desc': 'Steath disavtg.',
      'protection.armour.HEAVY_MAIL': 'Heavy mail',
      'protection.armour.HEAVY_MAIL.desc': 'STR 13 ; Steath disavtg.',
      'protection.shield.SHIELD': 'Shield',
      'protection.shield.SHIELD.desc': '-',
      'protection.shield.GREAT_SHIELD': 'Great shield',
      'protection.shield.GREAT_SHIELD.desc': 'STR 13 ; Steath disavtg.',
      'protection.others.DWARVEN_RESILIENCE': 'Dwarven resilience',
      'protection.others.DWARVEN_RESILIENCE.desc': 'Resist. + Avtg. vs Poison',
      'protection.others.FIGHTING_STYLE_DEFENCE': 'Fight style : Def.',
      'protection.others.FIGHTING_STYLE_DEFENCE.desc': '-',
      'protection.others.SLAYER_BODY': 'Def. without armour',
      'protection.others.SLAYER_BODY.desc': 'AC = 10 + DEX + CON',

      // Outils
      'tools.BREWERS_SUPPLIES': 'Brewers supplies',
      'tools.CALLIGRAPHERS_SUPPLIES': 'Calligraphers supplies',
      'tools.CARPENTERS_TOOLS': 'Carpenter Tools',
      'tools.WOODCARVER_TOOLS': 'Woodcarver tools',
      'tools.CARTOGRAPHERS_TOOLS': 'Cartographers tools',
      'tools.COBBLERS_TOOLS': 'Cobblers tools',
      'tools.COOKS_USTENSILS': 'Cooks ustensils',
      'tools.DICE_SET': 'Dice set',
      'tools.GAMING_SET': 'Gaming set',
      'tools.GLASSBLOWERS_TOOLS': 'Glassblowers tools',
      'tools.HERBALISM_KIT': 'Herbalism kit',
      'tools.JEWELLERS_TOOLS': 'Jewellers tools',
      'tools.LEATHERWORKERS_TOOLS': 'Leatherworkers tools',
      'tools.MASONS_TOOLS': 'Masons tools',
      'tools.PAINTERS_SUPPLIES': 'Painters supplies',
      'tools.POTTERS_TOOLS': 'Potters tools',
      'tools.SMITHS_TOOLS': 'Smiths tools',
      'tools.TINKERS_TOOLS': 'Tinkers tools',
      'tools.WEAVERS_TOOLS': 'Weaver tools',
      'tools.PIPE_SMOKING_SUPPLIES': 'Pipe smoking supplies',
      'tools.POISON_TOOLS': 'Poison tools',
      'tools.THIEVES_TOOLS': 'Thieves tools',
      'tools.INSTRUMENT_DRUM': 'Instrument (Drum)',
      'tools.INSTRUMENT_FIDDLE': 'Instrument (Fiddle)',
      'tools.INSTRUMENT_FLUTE': 'Instrument (Flute)',
      'tools.INSTRUMENT_LUTE': 'Instrument (Lute)',
      'tools.INSTRUMENT_LYRE': 'Instrument (Lyre)',
      'tools.INSTRUMENT_HORN': 'Instrument (Horn)',
      'tools.INSTRUMENT_VIOL': 'Instrument (Viol)',
      'tools.INSTRUMENT_HARMONICA': 'Instrument (Harmonica)',
      'tools.INSTRUMENT_OTHER': 'Instrument (Other)',

      // avantages
      'avantage.STONE_CUNNING': 'Stone cunning : + bonus of proficiency x 2',
      'avantage.WOODEN_STEALTH': 'Avantage into forest',

      // features
      'feature.f': 'Dwarven Toughness',
      'feature.DWARVEN_TOUGHNESS.desc': '+1 HP/lvl',
    },
  },
};

