import { StandardOfLiving } from './standard-of-living.model';
import { Speed } from './speed.model';
import { Weapon } from './weapon.model';

export class Culture {

    static BARDING: Culture = {
        id: 'culture.BARDING',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 30 }
    };
    static BEORNING: Culture = {
        id: 'culture.BEORNING',
        stdOfLiving: StandardOfLiving.MARTIAL,
        speed: { feet: 30 }
    };
    static DUNEDAIN: Culture = {
        id: 'culture.DUNEDAIN',
        stdOfLiving: StandardOfLiving.MARTIAL,
        speed: { feet: 30 }
    };
    static DWARF_EREBOR: Culture = {
        id: 'culture.DWARF_EREBOR',
        stdOfLiving: StandardOfLiving.RICH,
        speed: { feet: 25, details: 'armoured' }
    };
    static DWARF_IRON_HILLS: Culture = {
        id: 'culture.DWARF_IRON_HILLS',
        stdOfLiving: StandardOfLiving.RICH,
        speed: { feet: 25 }
    };
    static DWARF_WEAPONS: string[] = [
        Weapon.AXE.id, Weapon.GREAT_AXE.id, Weapon.HAND_AXE.id, Weapon.HAMMER.id, Weapon.WARHAMMER.id
    ];
    static ELF_MIRKWOOD: Culture = {
        id: 'culture.ELF_MIRKWOOD',
        stdOfLiving: StandardOfLiving.MARTIAL,
        speed: { feet: 30 }
    };
    static HOBBIT_HARFOOT: Culture = {
        id: 'culture.HOBBIT_HARFOOT',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 25 }
    };
    static HOBBIT_STOOR: Culture = {
        id: 'culture.HOBBIT_STOOR',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 25 }
    };
    static HOBBIT_FALLOWHIDE: Culture = {
        id: 'culture.HOBBIT_FALLOWHIDE',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 25 }
    };
    static MEN_OF_BREE: Culture = {
        id: 'culture.MEN_OF_BREE',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 30 }
    };
    static MEN_OF_THE_LAKE: Culture = {
        id: 'culture.MEN_OF_THE_LAKE',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 30 }
    };
    static MEN_OF_MINAS_TIRITH: Culture = {
        id: 'culture.MEN_OF_MINAS_TIRITH',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 30 }
    };
    static RIDERS_OF_ROHAN: Culture = {
        id: 'culture.RIDERS_OF_ROHAN',
        stdOfLiving: StandardOfLiving.PROSPEROUS,
        speed: { feet: 30 }
    };
    static WOODMEN_OF_WILDERLAND: Culture = {
        id: 'culture.WOODMEN_OF_WILDERLAND',
        stdOfLiving: StandardOfLiving.MARTIAL,
        speed: { feet: 30 }
    };
    static ALL: Map<string, Culture> = new Map([
        ['culture.BARDING', Culture.BARDING],
        ['culture.DUNEDAIN', Culture.DUNEDAIN],
        ['culture.BEORNING', Culture.BEORNING],
        ['culture.DUNEDAIN', Culture.DUNEDAIN],
        ['culture.DWARF_EREBOR', Culture.DWARF_EREBOR],
        ['culture.DWARF_IRON_HILLS', Culture.DWARF_IRON_HILLS],
        ['culture.ELF_MIRKWOOD', Culture.ELF_MIRKWOOD],
        ['culture.HOBBIT_HARFOOT', Culture.HOBBIT_HARFOOT],
        ['culture.HOBBIT_STOOR', Culture.HOBBIT_STOOR],
        ['culture.HOBBIT_FALLOWHIDE', Culture.HOBBIT_FALLOWHIDE],
        ['culture.MEN_OF_BREE', Culture.MEN_OF_BREE],
        ['culture.MEN_OF_THE_LAKE', Culture.MEN_OF_THE_LAKE],
        ['culture.MEN_OF_MINAS_TIRITH', Culture.MEN_OF_MINAS_TIRITH],
        ['culture.RIDERS_OF_ROHAN', Culture.RIDERS_OF_ROHAN],
        ['culture.WOODMEN_OF_WILDERLAND', Culture.WOODMEN_OF_WILDERLAND]
    ]);

    id: string;
    stdOfLiving: StandardOfLiving;
    speed: Speed;
}

