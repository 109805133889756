import { PennyValue } from '../commons/penny';

export class ProtectionType {

    static ARMOUR_LIGHT: ProtectionType = {
        id: 'protection.type.ARMOUR_LIGHT'
    };
    static ARMOUR_MEDIUM: ProtectionType = {
        id: 'protection.type.ARMOUR_MEDIUM'
    };
    static ARMOUR_HEAVY: ProtectionType = {
        id: 'protection.type.ARMOUR_HEAVY'
    };
    static SHIELD: ProtectionType = {
        id: 'protection.type.SHIELD'
    };
    static OTHERS: ProtectionType = {
        id: 'protection.type.OTHERS'
    };

    static ALL: Map<string, ProtectionType> = new Map([
        ['protection.type.ARMOUR_LIGHT', ProtectionType.ARMOUR_LIGHT],
        ['protection.type.ARMOUR_MEDIUM', ProtectionType.ARMOUR_MEDIUM],
        ['protection.type.ARMOUR_HEAVY', ProtectionType.ARMOUR_HEAVY],
        ['protection.type.SHIELD', ProtectionType.SHIELD],
        ['protection.type.OTHERS', ProtectionType.OTHERS],
    ]);

    id: string;

    static get(id: string): ProtectionType {
        return ProtectionType.ALL.get(id);
    }

    static values(): IterableIterator<ProtectionType> {
        return ProtectionType.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return ProtectionType.ALL.keys();
    }
}

export class Protection {

    // -------------- light armours -------------- //
    static LEATHER_JERKIN: Protection = {
        id: 'protection.armour.LEATHER_JERKIN',
        type: ProtectionType.ARMOUR_LIGHT,
        AC: 11,
        DexModifMax: null,
        requiredStrength: null,
        disavantagedStealth: false,
        weight: 10,
        cost: PennyValue.new(0, 10, 0)
    };
    static LEATHER_CORSLET: Protection = {
        id: 'protection.armour.LEATHER_CORSLET',
        type: ProtectionType.ARMOUR_LIGHT,
        AC: 12,
        DexModifMax: null,
        requiredStrength: null,
        disavantagedStealth: false,
        weight: 13,
        cost: PennyValue.new(0, 45, 0)
    };
    // -------------- medium armours -------------- //
    static HIDE: Protection = {
        id: 'protection.armour.HIDE',
        type: ProtectionType.ARMOUR_MEDIUM,
        AC: 12,
        DexModifMax: 2,
        requiredStrength: null,
        disavantagedStealth: false,
        weight: 12,
        cost: PennyValue.new(0, 10, 0)
    };
    static CORSLET_OF_MAIL: Protection = {
        id: 'protection.armour.CORSLET_OF_MAIL',
        type: ProtectionType.ARMOUR_MEDIUM,
        AC: 13,
        DexModifMax: 2,
        requiredStrength: null,
        disavantagedStealth: false,
        weight: 20,
        cost: PennyValue.new(0, 50, 0)
    };
    static SCALE_HAUBERK: Protection = {
        id: 'protection.armour.SCALE_HAUBERK',
        type: ProtectionType.ARMOUR_MEDIUM,
        AC: 14,
        DexModifMax: 2,
        requiredStrength: null,
        disavantagedStealth: true,
        weight: 45,
        cost: PennyValue.new(0, 60, 0)
    };
    // -------------- heavy armours -------------- //
    static RING_MAIL: Protection = {
        id: 'protection.armour.RING_MAIL',
        type: ProtectionType.ARMOUR_HEAVY,
        AC: 14,
        DexModifMax: 0,
        requiredStrength: null,
        disavantagedStealth: true,
        weight: 40,
        cost: PennyValue.new(0, 40, 0)
    };
    static HEAVY_MAIL: Protection = {
        id: 'protection.armour.HEAVY_MAIL',
        type: ProtectionType.ARMOUR_HEAVY,
        AC: 16,
        DexModifMax: 0,
        requiredStrength: 13,
        disavantagedStealth: true,
        weight: 55,
        cost: PennyValue.new(0, 75, 0)
    };
    // -------------- shields -------------- //
    static SHIELD: Protection = {
        id: 'protection.shield.SHIELD',
        type: ProtectionType.SHIELD,
        AC: 2,
        DexModifMax: null,
        requiredStrength: null,
        disavantagedStealth: false,
        weight: 6,
        cost: PennyValue.new(0, 10, 0)
    };
    static GREAT_SHIELD: Protection = {
        id: 'protection.shield.GREAT_SHIELD',
        type: ProtectionType.SHIELD,
        AC: 4,
        DexModifMax: null,
        requiredStrength: 13,
        disavantagedStealth: true,
        weight: 35,
        cost: PennyValue.new(0, 30, 0)
    };
    // -------------- autre -------------- //
    static DWARVEN_RESILIENCE: Protection = {
        id: 'protection.others.DWARVEN_RESILIENCE',
        type: ProtectionType.OTHERS,
        AC: null,
        DexModifMax: null,
        requiredStrength: 0,
        disavantagedStealth: false,
        weight: null,
        cost: null
    };
    static FIGHTING_STYLE_DEFENCE: Protection = {
        id: 'protection.others.FIGHTING_STYLE_DEFENCE',
        type: ProtectionType.OTHERS,
        AC: 1,
        DexModifMax: null,
        requiredStrength: 0,
        disavantagedStealth: false,
        weight: null,
        cost: null
    };
    static SLAYER_BODY: Protection = {
        id: 'protection.others.SLAYER_BODY',
        type: ProtectionType.OTHERS,
        AC: null,
        DexModifMax: null,
        requiredStrength: 0,
        disavantagedStealth: false,
        weight: null,
        cost: null
    };

    static ALL: Map<string, Protection> = new Map([
        ['protection.armour.LEATHER_JERKIN', Protection.LEATHER_JERKIN],
        ['protection.armour.LEATHER_CORSLET', Protection.LEATHER_CORSLET],
        ['protection.armour.HIDE', Protection.HIDE],
        ['protection.armour.CORSLET_OF_MAIL', Protection.CORSLET_OF_MAIL],
        ['protection.armour.SCALE_HAUBERK', Protection.SCALE_HAUBERK],
        ['protection.armour.RING_MAIL', Protection.RING_MAIL],
        ['protection.armour.HEAVY_MAIL', Protection.HEAVY_MAIL],
        ['protection.shield.SHIELD', Protection.SHIELD],
        ['protection.shield.GREAT_SHIELD', Protection.GREAT_SHIELD],
        ['protection.others.DWARVEN_RESILIENCE', Protection.DWARVEN_RESILIENCE],
        ['protection.others.FIGHTING_STYLE_DEFENCE', Protection.FIGHTING_STYLE_DEFENCE],
    ]);

    id: string;
    type: ProtectionType;
    AC: number;
    DexModifMax: number;
    requiredStrength: number;
    disavantagedStealth: boolean;
    weight: number;
    description?: string;
    cost: PennyValue;

    static get(id: string): Protection {
        return Protection.ALL.get(id);
    }

    static values(): IterableIterator<Protection> {
        return Protection.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return Protection.ALL.keys();
    }

}
