import { Injectable } from '@angular/core';
import { BackgroundType } from '../model/character/background-type.model';

@Injectable()
export class TextRendererFr {

    name(bg: BackgroundType): string {
        let text: string;
        switch (bg) {
            case BackgroundType.LOYAL_SERVANT:
                text = 'Fidèle serviteur';
                break;
            case BackgroundType.DOOMED_TO_DIE:
                text = 'Destin Funeste';
                break;
            case BackgroundType.DRIVEN_FROM_HOME:
                text = 'Banni';
                break;
            case BackgroundType.EMISSARY_OF_YOUR_PEOPLE:
                text = 'Émissaire du peuple';
                break;
            case BackgroundType.FALLEN_SCION:
                text = 'Lignage Déchu';
                break;
            case BackgroundType.HUNTED_BY_THE_SHADOW:
                text = 'Traqué par l\'Ombre';
                break;
            case BackgroundType.LURE_OF_THE_ROAD:
                text = 'L\'Attrait de la Route';
                break;
            case BackgroundType.OATHSWORN:
                text = 'Tenu par Serment';
                break;
            case BackgroundType.RELUCTANT_ADVENTURER:
                text = 'Aventurier réticent';
                break;
            case BackgroundType.SEEKER_OF_THE_LOST:
                text = 'Chercheur';
                break;
            case BackgroundType.THE_HARROWED:
                text = 'Hanté par les Rêves';
                break;
            case BackgroundType.THE_MAGICIAN:
                text = 'Le Magicien';
                break;
            case BackgroundType.WORLD_WEARY:
                text = 'Lassé du Monde';
                break;
            default:
                text = '';
                break;
        }
        return text;
    }

}
