import { DamageType } from './damage-type.enum';
import { Dice } from '../commons';
import { PennyValue } from '../commons/penny';
import { Ability } from './ability.model';

export class WeaponType {
    static SIMPLE: WeaponProperty = {
        id: 'weapon.type.SIMPLE'
    };
    static MARTIAL: WeaponProperty = {
        id: 'weapon.type.MARTIAL'
    };
    id: string;
}

export class WeaponProperty {

    static DWARF_FORGED: WeaponProperty = {
        id: 'weapon.property.DWARF_FORGED'
    };
    static LIGHT: WeaponProperty = {
        id: 'weapon.property.LIGHT'
    };
    static HEAVY: WeaponProperty = {
        id: 'weapon.property.HEAVY'
    };
    static FINESSE: WeaponProperty = {
        id: 'weapon.property.FINESSE'
    };
    static REACH: WeaponProperty = {
        id: 'weapon.property.REACH'
    };
    static THROWN_20_60: WeaponProperty = {
        id: 'weapon.property.THROWN_20_60'
    };
    static TWO_HANDED: WeaponProperty = {
        id: 'weapon.property.TWO_HANDED'
    };
    static VERSATILE_1D4: WeaponProperty = {
        id: 'weapon.property.VERSATILE_1D4'
    };
    static VERSATILE_1D6: WeaponProperty = {
        id: 'weapon.property.VERSATILE_1D6'
    };
    static VERSATILE_1D8: WeaponProperty = {
        id: 'weapon.property.VERSATILE_1D8'
    };
    static VERSATILE_1D10: WeaponProperty = {
        id: 'weapon.property.VERSATILE_1D10'
    };
    static VERSATILE_1D12: WeaponProperty = {
        id: 'weapon.property.VERSATILE_1D12'
    };
    static AMMUNITION_80_320: WeaponProperty = {
        id: 'weapon.property.AMMUNITION_80_320'
    };
    static AMMUNITION_30_120: WeaponProperty = {
        id: 'weapon.property.AMMUNITION_30_120'
    };
    static AMMUNITION_150_600: WeaponProperty = {
        id: 'weapon.property.AMMUNITION_150_600'
    };

    static ALL: Map<string, WeaponProperty> = new Map([
        ['weapon.property.DWARF_FORGED', WeaponProperty.DWARF_FORGED],
        ['weapon.property.LIGHT', WeaponProperty.LIGHT],
        ['weapon.property.HEAVY', WeaponProperty.HEAVY],
        ['weapon.property.FINESSE', WeaponProperty.FINESSE],
        ['weapon.property.REACH', WeaponProperty.REACH],
        ['weapon.property.THROWN_20_60', WeaponProperty.THROWN_20_60],
        ['weapon.property.TWO_HANDED', WeaponProperty.TWO_HANDED],
        ['weapon.property.VERSATILE_1D4', WeaponProperty.VERSATILE_1D4],
        ['weapon.property.VERSATILE_1D6', WeaponProperty.VERSATILE_1D6],
        ['weapon.property.VERSATILE_1D8', WeaponProperty.VERSATILE_1D8],
        ['weapon.property.VERSATILE_1D10', WeaponProperty.VERSATILE_1D10],
        ['weapon.property.VERSATILE_1D12', WeaponProperty.VERSATILE_1D12],
        ['weapon.property.AMMUNITION_80_320', WeaponProperty.AMMUNITION_80_320],
        ['weapon.property.AMMUNITION_30_120', WeaponProperty.AMMUNITION_30_120],
        ['weapon.property.AMMUNITION_150_600', WeaponProperty.AMMUNITION_150_600]
    ]);

    id: string;

    static get(id: string): WeaponProperty {
        return WeaponProperty.ALL.get(id);
    }

    static values(): IterableIterator<WeaponProperty> {
        return WeaponProperty.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return WeaponProperty.ALL.keys();
    }
}

export class Weapon {

    static CLUB: Weapon = {
        id: 'weapon.CLUB',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d4,
        dmgType: DamageType.BLUDGEONING,
        ability: Ability.STR,
        properties: [WeaponProperty.LIGHT],
        weight: 2,
        cost: PennyValue.new(0, 0, 24)
    };
    static DAGGER: Weapon = {
        id: 'weapon.DAGGER',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d4,
        dmgType: DamageType.PIERCING,
        ability: Ability.DEX,
        properties: [WeaponProperty.FINESSE,
        WeaponProperty.LIGHT,
        WeaponProperty.THROWN_20_60],
        weight: 1,
        cost: PennyValue.new(0, 2, 0)
    };
    static GREAT_CLUB: Weapon = {
        id: 'weapon.GREAT_CLUB',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d8,
        dmgType: DamageType.BLUDGEONING,
        ability: Ability.STR,
        properties: [WeaponProperty.TWO_HANDED],
        weight: 10,
        cost: PennyValue.new(0, 0, 48)
    };
    static HAND_AXE: Weapon = {
        id: 'weapon.HAND_AXE',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d6,
        dmgType: DamageType.SLASHING,
        ability: Ability.STR,
        properties: [WeaponProperty.LIGHT,
        WeaponProperty.THROWN_20_60],
        weight: 2,
        cost: PennyValue.new(0, 5, 0)
    };
    static HAMMER: Weapon = {
        id: 'weapon.HAMMER',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d4,
        dmgType: DamageType.BLUDGEONING,
        ability: Ability.STR,
        properties: [WeaponProperty.LIGHT,
        WeaponProperty.THROWN_20_60],
        weight: 2,
        cost: PennyValue.new(0, 2, 0)
    };
    static MACE: Weapon = {
        id: 'weapon.MACE',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d6,
        dmgType: DamageType.BLUDGEONING,
        ability: Ability.STR,
        properties: [],
        weight: 4,
        cost: PennyValue.new(0, 4, 0)
    };
    static STAFF: Weapon = {
        id: 'weapon.STAFF',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d6,
        dmgType: DamageType.BLUDGEONING,
        ability: Ability.STR,
        properties: [WeaponProperty.VERSATILE_1D8],
        weight: 4,
        cost: PennyValue.new(0, 5, 0)
    };
    static SPEAR: Weapon = {
        id: 'weapon.SPEAR',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d6,
        dmgType: DamageType.PIERCING,
        ability: Ability.STR,
        properties: [WeaponProperty.THROWN_20_60,
        WeaponProperty.VERSATILE_1D8],
        weight: 3,
        cost: PennyValue.new(0, 3, 0)
    };
    // light ranged
    static SHORT_BOW: Weapon = {
        id: 'weapon.SHORT_BOW',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d6,
        dmgType: DamageType.PIERCING,
        ability: Ability.DEX,
        properties: [WeaponProperty.AMMUNITION_80_320,
        WeaponProperty.TWO_HANDED],
        weight: 2,
        cost: PennyValue.new(0, 25, 0)
    };
    static SLING: Weapon = {
        id: 'weapon.SLING',
        type: WeaponType.SIMPLE,
        nbDice: 1,
        diceType: Dice.d4,
        dmgType: DamageType.BLUDGEONING,
        ability: Ability.DEX,
        properties: [WeaponProperty.AMMUNITION_30_120],
        weight: 0,
        cost: PennyValue.new(0, 0, 24)
    };
    // martial
    static AXE: Weapon = {
        id: 'weapon.AXE',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d8,
        dmgType: DamageType.SLASHING,
        ability: Ability.STR,
        properties: [WeaponProperty.VERSATILE_1D10],
        weight: 4,
        cost: PennyValue.new(0, 10, 0)
    };
    static GREAT_AXE: Weapon = {
        id: 'weapon.GREAT_AXE',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d12,
        dmgType: DamageType.SLASHING,
        ability: Ability.STR,
        properties: [WeaponProperty.HEAVY,
        WeaponProperty.TWO_HANDED],
        weight: 7,
        cost: PennyValue.new(0, 30, 0)
    };
    static GREAT_SPEAR: Weapon = {
        id: 'weapon.GREAT_SPEAR',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d12,
        dmgType: DamageType.PIERCING,
        ability: Ability.STR,
        properties: [WeaponProperty.HEAVY,
        WeaponProperty.REACH,
        WeaponProperty.TWO_HANDED],
        weight: 9,
        cost: PennyValue.new(0, 20, 0)
    };
    static HEAVY_SCIMITAR: Weapon = {
        id: 'weapon.HEAVY_SCIMITAR',
        type: WeaponType.MARTIAL,
        nbDice: 2,
        diceType: Dice.d6,
        dmgType: DamageType.SLASHING,
        ability: Ability.STR,
        properties: [WeaponProperty.HEAVY,
        WeaponProperty.TWO_HANDED],
        weight: 6,
        cost: PennyValue.new(0, 50, 0)
    };
    static LONG_SWORD: Weapon = {
        id: 'weapon.LONG_SWORD',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d8,
        dmgType: DamageType.SLASHING,
        ability: Ability.STR,
        properties: [WeaponProperty.VERSATILE_1D10],
        weight: 3,
        cost: PennyValue.new(0, 40, 0)
    };
    static MATTOCK: Weapon = {
        id: 'weapon.MATTOCK',
        type: WeaponType.MARTIAL,
        nbDice: 2,
        diceType: Dice.d6,
        dmgType: DamageType.PIERCING,
        ability: Ability.STR,
        properties: [WeaponProperty.HEAVY,
        WeaponProperty.TWO_HANDED],
        weight: 10,
        cost: PennyValue.new(0, 20, 0)
    };
    static SCIMITAR: Weapon = {
        id: 'weapon.SCIMITAR',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d6,
        dmgType: DamageType.SLASHING,
        ability: Ability.DEX,
        properties: [WeaponProperty.FINESSE,
        WeaponProperty.LIGHT],
        weight: 3,
        cost: PennyValue.new(0, 25, 0)
    };
    static SHORT_SWORD: Weapon = {
        id: 'weapon.SHORT_SWORD',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d6,
        dmgType: DamageType.PIERCING,
        ability: Ability.DEX,
        properties: [WeaponProperty.FINESSE,
        WeaponProperty.LIGHT],
        weight: 2,
        cost: PennyValue.new(0, 10, 0)
    };
    static BROADSWORD: Weapon = {
        id: 'weapon.BROADSWORD',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d8,
        dmgType: DamageType.SLASHING,
        ability: Ability.DEX,
        properties: [WeaponProperty.FINESSE],
        weight: 3,
        cost: PennyValue.new(0, 30, 0)
    };
    static WARHAMMER: Weapon = {
        id: 'weapon.WARHAMMER',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d8,
        dmgType: DamageType.BLUDGEONING,
        ability: Ability.STR,
        properties: [WeaponProperty.VERSATILE_1D10],
        weight: 2,
        cost: PennyValue.new(0, 15, 0)
    };
    // martial ranged
    static GREAT_BOW: Weapon = {
        id: 'weapon.GREAT_BOW',
        type: WeaponType.MARTIAL,
        nbDice: 1,
        diceType: Dice.d8,
        dmgType: DamageType.PIERCING,
        ability: Ability.DEX,
        properties: [WeaponProperty.AMMUNITION_150_600,
        WeaponProperty.HEAVY,
        WeaponProperty.TWO_HANDED],
        weight: 3,
        cost: PennyValue.new(0, 50, 0)
    };
    static ALL: Map<string, Weapon> = new Map([
        ['weapon.AXE', Weapon.AXE],
        ['weapon.BROADSWORD', Weapon.BROADSWORD],
        ['weapon.CLUB', Weapon.CLUB],
        ['weapon.DAGGER', Weapon.DAGGER],
        ['weapon.GREAT_AXE', Weapon.GREAT_AXE],
        ['weapon.GREAT_BOW', Weapon.GREAT_BOW],
        ['weapon.GREAT_CLUB', Weapon.GREAT_CLUB],
        ['weapon.GREAT_SPEAR', Weapon.GREAT_SPEAR],
        ['weapon.HAMMER', Weapon.HAMMER],
        ['weapon.HAND_AXE', Weapon.HAND_AXE],
        ['weapon.HEAVY_SCIMITAR', Weapon.HEAVY_SCIMITAR],
        ['weapon.LONG_SWORD', Weapon.LONG_SWORD],
        ['weapon.MACE', Weapon.MACE],
        ['weapon.MATTOCK', Weapon.MATTOCK],
        ['weapon.SCIMITAR', Weapon.SCIMITAR],
        ['weapon.SHORT_BOW', Weapon.SHORT_BOW],
        ['weapon.SHORT_SWORD', Weapon.SHORT_SWORD],
        ['weapon.SLING', Weapon.SLING],
        ['weapon.SPEAR', Weapon.SPEAR],
        ['weapon.STAFF', Weapon.STAFF],
        ['weapon.WARHAMMER', Weapon.WARHAMMER]
    ]);

    id: string;
    type: WeaponType;
    nbDice: number;
    diceType: string;
    dmgModif?: number;
    hitModif?: number;
    dmgType: DamageType;
    ability: Ability;
    properties: WeaponProperty[];
    weight: number;
    cost: PennyValue;

    static get(id: string): Weapon {
        return Weapon.ALL.get(id);
    }

    static values(): IterableIterator<Weapon> {
        return Weapon.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return Weapon.ALL.keys();
    }

}
