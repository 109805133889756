import { Skill } from './skill.model';
import { BgFeature } from './bg-feature';

export class BackgroundType {
    static LOYAL_SERVANT: BackgroundType = {
        id: 'background.LOYAL_SERVANT',
        proficiencies: [Skill.INSIGHT, Skill.TRADITIONS],
        feature: BgFeature.INSEPARABLE,
    };
    static DOOMED_TO_DIE: BackgroundType = {
        id: 'background.DOOMED_TO_DIE',
        proficiencies: [Skill.ATHLETICS, Skill.INTIMIDATION],
        feature: BgFeature.DARK_FOREBODING
    };
    static DRIVEN_FROM_HOME: BackgroundType = {
        id: 'background.DRIVEN_FROM_HOME',
        proficiencies: [Skill.INTIMIDATION, Skill.SURVIVAL],
        feature: BgFeature.INFAMOUS
    };
    static EMISSARY_OF_YOUR_PEOPLE: BackgroundType = {
        id: 'background.EMISSARY_OF_YOUR_PEOPLE',
        proficiencies: [Skill.PERSUASION, Skill.TRADITIONS],
        feature: BgFeature.SIGIL_OF_YOUR_MASTER
    };
    static FALLEN_SCION: BackgroundType = {
        id: 'background.FALLEN_SCION',
        proficiencies: [Skill.HISTORY, Skill.TRADITIONS],
        feature: BgFeature.NOBLE_BEARING
    };
    static BLACK_SHIELD_1: BackgroundType = {
        id: 'background.BLACK_SHIELD_1',
        proficiencies: [Skill.DECEPTION, Skill.TRADITIONS],
        feature: BgFeature.NOBLE_BEARING
    };
    static BLACK_SHIELD_2: BackgroundType = {
        id: 'background.BLACK_SHIELD_2',
        proficiencies: [Skill.HISTORY, Skill.DECEPTION],
        feature: BgFeature.NOBLE_BEARING
    };
    static THE_HARROWED: BackgroundType = {
        id: 'background.THE_HARROWED',
        proficiencies: [Skill.LORE, Skill.INVESTIGATION],
        feature: BgFeature.FOREKNOWLEDGE
    };
    static STORYTELLER: BackgroundType = {
        id: 'background.STORYTELLER',
        proficiencies: [Skill.LORE, Skill.PERFORMANCE],
        feature: BgFeature.FOREKNOWLEDGE
    };
    static HUNTED_BY_THE_SHADOW: BackgroundType = {
        id: 'background.HUNTED_BY_THE_SHADOW',
        proficiencies: [Skill.STEALTH, Skill.SURVIVAL],
        feature: BgFeature.TROUBLING_TO_THE_WISE
    };
    static LURE_OF_THE_ROAD: BackgroundType = {
        id: 'background.LURE_OF_THE_ROAD',
        proficiencies: [Skill.ANIMAL_HANDLING, Skill.SURVIVAL],
        feature: BgFeature.WEATHER_LORE
    };
    static THE_MAGICIAN: BackgroundType = {
        id: 'background.THE_MAGICIAN',
        proficiencies: [Skill.PERFORMANCE, Skill.SLEIGHT_OF_HAND],
        feature: BgFeature.AIR_OF_MAGIC
    };
    static FOLK_HEALER: BackgroundType = {
        id: 'background.FOLK_HEALER',
        proficiencies: [Skill.PERFORMANCE, Skill.MEDICINE],
        feature: BgFeature.AIR_OF_MAGIC
    };
    static OATHSWORN: BackgroundType = {
        id: 'background.OATHSWORN',
        proficiencies: [Skill.INTIMIDATION, Skill.TRADITIONS],
        feature: BgFeature.MIGHTY_OATH
    };
    static DEVOTED_FRIEND: BackgroundType = {
        id: 'background.DEVOTED_FRIEND',
        proficiencies: [Skill.INSIGHT, Skill.PERSUASION],
        feature: BgFeature.MIGHTY_OATH
    };
    static RELUCTANT_ADVENTURER: BackgroundType = {
        id: 'background.RELUCTANT_ADVENTURER',
        proficiencies: [Skill.MEDICINE, Skill.NATURE],
        feature: BgFeature.PATHETIC_AND_BEDRAGGLED
    };
    static SEEKER_OF_THE_LOST: BackgroundType = {
        id: 'background.SEEKER_OF_THE_LOST',
        proficiencies: [Skill.LORE, Skill.INVESTIGATION],
        feature: BgFeature.LORE_OF_THE_LOST
    };
    static WORLD_WEARY: BackgroundType = {
        id: 'background.WORLD_WEARY',
        proficiencies: [Skill.HISTORY, Skill.INSIGHT],
        feature: BgFeature.SEASONED_CONNECTIONS
    };
    static CALL_OF_THE_SEA: BackgroundType = {
        id: 'background.CALL_OF_THE_SEA',
        proficiencies: [Skill.HISTORY, Skill.INSIGHT],
        feature: BgFeature.SEA_DREAMS
    };

    id: string;
    proficiencies: Skill[];
    feature: BgFeature;
}
