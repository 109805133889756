import { Background } from './background.model';
import { Virtue } from './virtue.model';
import { Weapon } from './weapon.model';
import { Protection } from './protection.model';
import { PennyValue } from '../commons/penny';
import { Feature } from './feature';

export class Character {

    name: string;
    culture: string;
    background: Background;
    clazz: string;
    clazzSpec: string;
    level: number;
    proficiencyBonus: number;
    virtues: Virtue[];
    mastering: [string, number][];
    masteringMap?: Map<any, number>;
    avantages?: [string, any][];
    avantagesMap?: Map<string, any>;
    modifiers: { key: any, value: number }[];
    features: Feature[];
    scores: [string, number][];
    scoresMap?: Map<string, number>;
    permaShadow: number;
    HP: number;
    equipment: {
        money: PennyValue;
        weapons?: Weapon[];
        protections?: {enabled: boolean, protection: Protection}[];
        tools?: string[];
        others?: string[];
    };
    about: {
        title?: string;
        age: string;
        origin: string;
        size: string;
        weight: string;
        look: string;
        parents?: string[];
        relations?: string[];
        patrons?: string[];
        titles?: string[];
        sanctuaries?: string[];
        adventures?: {
            title: string;
            date?: string;
            summary?: string;
        }[];
    };

    static loadData(char: Character) {
        char.scoresMap = new Map<string, number>(char.scores);
        char.avantagesMap = char.avantages ?  new Map<string, any>(char.avantages) : new Map<string, any>();
        char.masteringMap = new Map<string, number>(char.mastering);
        return char;
    }

    static getModif(char: Character, id: string): number {
        const value = char.scoresMap.get(id);
        return -Math.round((-value + 10) / 2);
    }

    static getScore(char: Character, id: string): number {
        return char.scoresMap.get(id);
    }

    static getWeaponMasteringLevel(char: Character, weapon: Weapon): number {
        let level;
        if (weapon) {
            level = char.masteringMap.get(weapon.id);
            if (level) {
                return level;
            }
            level = char.masteringMap.get(weapon.type.id);
            if (level) {
                return level;
            }
        }
        return 0;
    }

    static getMasteringLevel(char: Character, id: string): number {
        const level = char.masteringMap.get(id);
        if (level) {
            return level;
        }
        return 0;
    }
}
