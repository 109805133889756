import { Culture } from './culture.model';
import { Skill } from './skill.model';
import { AbilityModif } from './ability-modif.model';
import { Miscellaneous } from './miscellaneous.model';
import { Tools } from './tools.enum';
import { Weapon } from './weapon.model';
import { Protection } from './protection.model';

export class CultureSettings {

    culture: Culture;
    bonus?: AbilityModif[];
    skillProficiencies?: string[];
    weaponProficiencies?: string[];
    armorProficiencies?: string[];
    toolsProficiencies?: string[];
    miscProficiencies?: string[];

    static isSkillProficiency(culture: CultureSettings, skill: Skill): boolean {
        for (const e of culture.skillProficiencies) {
            if (e === skill.id) {
                return true;
            }
        }
        return false;
    }
    static isArmorProficiency(culture: CultureSettings, armor: Protection): boolean {
        for (const e of culture.armorProficiencies) {
            if (e === armor.id) {
                return true;
            }
        }
        return false;
    }
    static isWeaponProficiency(culture: CultureSettings, weapon: Weapon): boolean {
        for (const e of culture.weaponProficiencies) {
            if (e === weapon.id) {
                return true;
            }
        }
        return false;
    }
    static isToolsProficiency(culture: CultureSettings, tools: Tools): boolean {
        for (const e of culture.toolsProficiencies) {
            if (e === tools.id) {
                return true;
            }
        }
        return false;
    }
    static isMiscProficiency(culture: CultureSettings, misc: any): boolean {
        for (const e of culture.miscProficiencies) {
            if (e === misc.id) {
                return true;
            }
        }
        return false;
    }

}
