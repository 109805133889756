import { Ability } from './ability.model';

export class Skill {
    static ACROBATICS: Skill = {
        id: 'skill.ACROBATICS',
        ability: Ability.DEX
    };
    static ANIMAL_HANDLING: Skill = {
        id: 'skill.ANIMAL_HANDLING',
        ability: Ability.WIS
    };
    static ATHLETICS: Skill = {
        id: 'skill.ATHLETICS',
        ability: Ability.STR
    };
    static DECEPTION: Skill = {
        id: 'skill.DECEPTION',
        ability: Ability.CHA
    };
    static HISTORY: Skill = {
        id: 'skill.HISTORY',
        ability: Ability.INT
    };
    static INSIGHT: Skill = {
        id: 'skill.INSIGHT',
        ability: Ability.WIS
    };
    static INTIMIDATION: Skill = {
        id: 'skill.INTIMIDATION',
        ability: Ability.CHA
    };
    static INVESTIGATION: Skill = {
        id: 'skill.INVESTIGATION',
        ability: Ability.INT
    };
    static LORE: Skill = {
        id: 'skill.LORE',
        ability: Ability.INT
    };
    static MEDICINE: Skill = {
        id: 'skill.MEDICINE',
        ability: Ability.WIS
    };
    static NATURE: Skill = {
        id: 'skill.NATURE',
        ability: Ability.INT
    };
    static PERCEPTION: Skill = {
        id: 'skill.PERCEPTION',
        ability: Ability.WIS
    };
    static PERFORMANCE: Skill = {
        id: 'skill.PERFORMANCE',
        ability: Ability.CHA
    };
    static PERSUASION: Skill = {
        id: 'skill.PERSUASION',
        ability: Ability.CHA
    };
    static RIDDLE: Skill = {
        id: 'skill.RIDDLE',
        ability: Ability.INT
    };
    static SHADOW_LORE: Skill = {
        id: 'skill.SHADOW_LORE',
        ability: Ability.INT
    };
    static SLEIGHT_OF_HAND: Skill = {
        id: 'skill.SLEIGHT_OF_HAND',
        ability: Ability.DEX
    };
    static STEALTH: Skill = {
        id: 'skill.STEALTH',
        ability: Ability.DEX
    };
    static SURVIVAL: Skill = {
        id: 'skill.SURVIVAL',
        ability: Ability.WIS
    };
    static TRADITIONS: Skill = {
        id: 'skill.TRADITIONS',
        ability: Ability.INT
    };

    static ALL: Map<string, Skill> = new Map<string, Skill>([
        ['skill.ACROBATICS', Skill.ACROBATICS],
        ['skill.ANIMAL_HANDLING', Skill.ANIMAL_HANDLING],
        ['skill.ATHLETICS', Skill.ATHLETICS],
        ['skill.DECEPTION', Skill.DECEPTION],
        ['skill.HISTORY', Skill.HISTORY],
        ['skill.INSIGHT', Skill.INSIGHT],
        ['skill.INTIMIDATION', Skill.INTIMIDATION],
        ['skill.INVESTIGATION', Skill.INVESTIGATION],
        ['skill.LORE', Skill.LORE],
        ['skill.MEDICINE', Skill.MEDICINE],
        ['skill.NATURE', Skill.NATURE],
        ['skill.PERCEPTION', Skill.PERCEPTION],
        ['skill.PERFORMANCE', Skill.PERFORMANCE],
        ['skill.PERSUASION', Skill.PERSUASION],
        ['skill.RIDDLE', Skill.RIDDLE],
        ['skill.SHADOW_LORE', Skill.SHADOW_LORE],
        ['skill.SLEIGHT_OF_HAND', Skill.SLEIGHT_OF_HAND],
        ['skill.STEALTH', Skill.STEALTH],
        ['skill.SURVIVAL', Skill.SURVIVAL],
        ['skill.TRADITIONS', Skill.TRADITIONS],
    ]);

    id: string;
    ability: Ability;

    static get(id: string): Skill {
        return Skill.ALL.get(id);
    }

    static values(): IterableIterator<Skill> {
        return Skill.ALL.values();
    }

    static keys(): IterableIterator<string> {
        return Skill.ALL.keys();
    }
}
