import { Pipe, PipeTransform } from '@angular/core';
import { Culture, Weapon, Class, Character } from '../model/character';
import { environment } from '../../environments/environment';

@Pipe({ name: 'lineWrap' })
export class LineWrapPipe implements PipeTransform {

    transform(str: any): string {
        if (typeof str === 'string') {
            while (str.indexOf('\n') >= 0) {
                str = str.replace('\n', '\<br\>');
            }
            return str;
        } else {
            return str;
        }
    }
}
