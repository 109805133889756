import { Component, OnInit, ViewChild, AfterViewInit, Inject, Renderer2 } from '@angular/core';
import { Howl } from 'howler';
import { Character, Weapon, Ability, Skill, Culture } from '../../model/character';
import { CharacterService, } from '../../services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import { Feature } from '../../model/character/feature';

@Component({
  selector: 'app-sda',
  templateUrl: './sda.component.html',
  styleUrls: ['./sda.component.css'],
  providers: [CharacterService]
})
export class SdaComponent implements OnInit, AfterViewInit {
  // map
  @ViewChild('map') map: Component;
  @ViewChild('adventures') adventures: Component;

  unlocked = false;
  displayMap = false;
  displayNote = false;
  env = environment;

  rivendellTheme = true;
  notes = '';

  // Character data
  data: Character = null;
  characters = ['Beli', 'Avada', 'Valandor'];
  gameMode = true;
  displayEquipment = false;

  title = 'sda';
  current = 'Beli';
  subpages = ['Nain d\'Erebor', 'Rôdeur : niveau 1', 'Background : The Harrowed'];
  subcurrent = this.subpages[0];
  musicON = true;
  musicList = ['assets/sound/ThePartyCamp.mp3', 'assets/sound/slavic-music-seredina-zimy.mp3'];
  musicIdx = 0;
  musics: Howl[] = new Array(this.musicList.length);
  fxScroll = new Howl({
    src: ['assets/sound/scroll.flac']
  });
  fxBook = new Howl({
    src: ['assets/sound/book-open.mp3']
  });
  fxPage = new Howl({
    src: ['assets/sound/page-turn.wav']
  });
  jsonCharURI: SafeUrl;
  src = '';
  preloadSrc = '';
  preloadImgHtml = '';

  constructor(private charService: CharacterService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2) {
      this.renderer.setStyle(document.body, 'backgroundImage', 'url(assets/img/rivendell.jpg)');
      this.renderer.setStyle(document.body, 'backgroundPosition', 'left top');
  }

  initMusicList(musicList: string[]) {
    for (let index = 0; index < musicList.length; index++) {
      const musicURI = musicList[index];
      this.initMusic(index, musicURI);
    }
  }
  initMusic(musicIdx: number, musicURI: string) {
    const music = new Howl({
      src: [musicURI],
      autoplay: false,
      loop: true,
      volume: 0.5,
      onfade: soundId => {
        if (music.volume() === 0) {
          music.pause();
          this.musicON = false;
          localStorage.setItem('music-ON', 'false');
        } else {
          this.musicON = true;
          localStorage.setItem('music-ON', 'true');
        }
      },
      onload: () => {
        // this.musicON = true;
      }
    });
    this.musics[musicIdx] = music;
  }

  ngOnInit() {
    this.initMusicList(this.musicList);
    if (localStorage.getItem('music-idx')) {
      this.musicIdx = +localStorage.getItem('music-idx');
      if (this.musicIdx >= this.musics.length) {
        this.musicIdx = 0;
      }
    } else {
      this.musicIdx = 0;
    }
    if (localStorage.getItem('music-ON')) {
      if (localStorage.getItem('music-ON') === 'true') {
        this.playMusic();
      } else {
        this.musicON = false;
      }
    } else {
      this.playMusic();
    }
    this.openRiddle();
    this.notes = localStorage.getItem('notes');
  }

  ngAfterViewInit(): void {
  }

  toggleMusic() {
    if (this.musicON) {
      this.stopMusic();
    } else {
      this.playMusic();
    }
  }

  stopMusic() {
    this.musics[this.musicIdx].fade(0.5, 0, 2000);
  }

  playMusic() {
    this.musics[this.musicIdx].play();
    this.musics[this.musicIdx].fade(0, 0.5, 2000);
    localStorage.setItem('music-idx', '' + this.musicIdx);
  }

  nextMusic() {
    this.musics[this.musicIdx].stop();
    this.musicIdx = (this.musicIdx + 1) % this.musics.length;
    this.playMusic();
  }

  soundScroll() {
    this.fxScroll.play();
  }

  soundBook() {
    this.fxBook.play();
  }

  soundPage() {
    this.fxPage.play();
  }

  navToSubpage(subpage: string) {
    this.subcurrent = subpage;
    this.fxScroll.play();
  }

  // Character functions

  getInitData() {
    this.getData(this.characters[0]);
  }

  getData(charName: string) {
    this.data = this.charService.getData(charName);
    this.updateDownloadURI();
  }

  getDataFile(charName: string) {
    this.charService.getDataFile(charName).subscribe(
      res => {
        this.data = Character.loadData(JSON.parse(JSON.stringify(res)));
        this.updateDownloadURI();
      },
      error => { console.error('loadfile failed: ' + error); }
    );
  }

  updateDownloadURI() {
    const json = JSON.stringify(this.data);
    const blob = new Blob([json], { type: 'text/json' });
    const url = window.URL.createObjectURL(blob);
    this.jsonCharURI = this.sanitizer.bypassSecurityTrustUrl(url);
  }

  saveBook() {
    localStorage.setItem('book', JSON.stringify(this.data));
  }

  getWeaponModif(weapon: Weapon): string {
    let modif = Character.getModif(this.data, weapon.ability.id);
    modif = modif + (weapon.dmgModif ? weapon.dmgModif : 0);
    modif = modif + this.data.proficiencyBonus * Character.getWeaponMasteringLevel(this.data, weapon);
    return this.sign(modif);
  }

  getDamageModif(weapon: Weapon): string {
    let modif = Character.getModif(this.data, weapon.ability.id);
    modif = modif + (weapon.dmgModif ? weapon.dmgModif : 0);
    return this.sign(modif);
  }

  getSkillModif(id: string): string {
    const skill = Skill.get(id);
    const modif = Character.getModif(this.data, skill.ability.id)
      + this.data.proficiencyBonus * Character.getMasteringLevel(this.data, id);
    return this.sign(modif);
  }
  getSkillModifNumber(id: string): number {
    const skill = Skill.get(id);
    return Character.getModif(this.data, skill.ability.id) + this.data.proficiencyBonus * Character.getMasteringLevel(this.data, id);
  }

  getSkillMasteringLevel(id: string): number {
    return Character.getMasteringLevel(this.data, id);
  }
  getAbilityScore(id: string): number {
    return Character.getScore(this.data, id);
  }
  getAbilityModif(id: string): string {
    return this.sign(Character.getModif(this.data, id));
  }
  getAbilitySave(id: string): string {
    const modif = Character.getModif(this.data, id)
      + this.data.proficiencyBonus * Character.getMasteringLevel(this.data, id);
    return this.sign(modif);
  }

  getAvantage(skillId: string) {
    return this.data.avantagesMap.get(skillId);
  }

  getSpeed(): number {
    return Culture.ALL.get(this.data.culture).speed.feet;
  }

  getAC(): number {
    let ca = 0;
    let bonusDex = Character.getModif(this.data, Ability.DEX.id);
    if (this.data.equipment.protections) {
      for (const item of this.data.equipment.protections) {
        if (item.enabled && item.protection.AC) {
          ca += item.protection.AC;
          if (item.protection.DexModifMax != null) {
            bonusDex = Math.min(bonusDex, item.protection.DexModifMax);
          }
        }
      }
    }
    return ca + bonusDex;
  }

  sign(value: number): string {
    if (value === 0) {
      return '+0';
    }
    if (!value) {
      return '';
    }
    if (value > 0) {
      return '+' + value;
    }
    return value.toString();
  }
  toggleDisplayMap() {
    this.displayMap = !this.displayMap;
  }
  toggleDisplayNote() {
    this.displayNote = !this.displayNote;
    if (this.displayNote) {
      this.renderer.selectRootElement('#noteTextAera').focus();
    }
  }

  hideProfyciency(profyciencyId: string) {
    return Skill.ALL.get(profyciencyId) != null || Ability.ALL.get(profyciencyId) != null;
  }

  toggleMode() {
    this.gameMode = !this.gameMode;
  }

  toggleEquipment() {
    this.displayEquipment = !this.displayEquipment;
  }

  toggleLangage() {
    if (environment.lang === 'fr') {
      environment.lang = 'en';
    } else if (environment.lang === 'en') {
      environment.lang = 'fr';
    } else {
      environment.lang = 'fr';
    }
    // todo refresh DOM
    const name = this.data.name;
    this.data = null;
    this.data = this.charService.getData(name);
  }

  toggleTheme() {
    if (this.rivendellTheme) {
      this.renderer.setStyle(document.body, 'backgroundImage', 'url(assets/img/bg4.jpg)');
      this.renderer.setStyle(document.body, 'backgroundPosition', 'center top');
    } else {
      this.renderer.setStyle(document.body, 'backgroundImage', 'url(assets/img/rivendell.jpg)');
      this.renderer.setStyle(document.body, 'backgroundPosition', 'left top');
    }
    this.rivendellTheme = !this.rivendellTheme;
  }

  saveNote(note: string) {
    localStorage.setItem('notes', note);
  }

  openRiddle() {
    this.soundPage();
    const dialogRef = this.dialog.open(RiddleDialog, {
      width: '300px',
      position: {
        top: '150px',
        left: 'calc(50vw - 150px)'
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.soundPage();
      this.unlocked = true;
      // this.toggleTheme();
    });
  }

  openDialog(event: MouseEvent, feature: Feature): void {
    const dialogRef = this.dialog.open(FeatureDialog, {
      width: '550px',
      data: feature,
      position: {
        top: 'calc(50vh - 100px)',
        left: 'calc(50% - 100px)'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.soundPage();
    });
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'feature-dialog',
  template: '<div class="dialogTitle" mat-dialog-title>This thing all things devours,<br/>'
  + 'Birds, beasts, trees, flowers,<br/>'
  + 'Gnaws iron, bites steel,<br/>'
  + 'Grinds hard stone to meal,<br/>'
  + 'Slays king, ruins town,<br/>'
  + 'And beats high mountain down.</div>'
  + '<form style="width:100%">'
  + '<mat-form-field style="width:100%" color="warn">'
  + '  <input matInput type="text" placeholder="Response..." (change)="check($event.target.value)">'
  + '</mat-form-field>'
  + '<button hidden="true">x</button>'
  + '</form>',
})
// tslint:disable-next-line:component-class-suffix
export class RiddleDialog {
  env = environment;
  resp = 'toto';

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data?: any) {
  }

  check(value: string) {
    console.log(JSON.stringify(value));
    console.log('>> ' + value);
    if (value) {
      const response = value.trim().toLocaleLowerCase();
      console.log(response);
      if (response === 'time' || response === 'the time') {
        this.dialog.closeAll();
      }
    }
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'feature-dialog',
  template: '<div class="dialogTitle" mat-dialog-title>{{data.id | dictionnary:env.lang}}</div>'
  + '<mat-dialog-content><div [innerHtml]="data.desc"></div></mat-dialog-content><br/>',
})
// tslint:disable-next-line:component-class-suffix
export class FeatureDialog {
  env = environment;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Feature) {
  }
}
