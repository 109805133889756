export class Miscellaneous {
    static NIGHT_VISION: Miscellaneous = {
        id: 'misc.NIGHT_VISION'
    };
    static POISON_RESISTANCE_ADVGT: Miscellaneous = {
        id: 'misc.POISON_RESISTANCE_ADVGT'
    };

    id: string;
}
