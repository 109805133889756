import { Component, OnInit, Inject, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import { DivinationService } from 'src/app/services';

declare var $: any;

@Component({
  selector: 'app-antika',
  templateUrl: './antika.component.html',
  styleUrls: ['./antika.component.css'],
  providers: [DivinationService]
})
export class AntikaComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private divinationService: DivinationService) { }

  @Output()
  private toIndex: EventEmitter<string> = new EventEmitter<string>();

  mode = 'game';
  card = 'notes';
  selectedCard = '';
  selectedCardFile = '';
  divinationId: Number = null;
  flipped = false;
  displayMap = false;
  musicON = true;
  music: Howl;
  fxCard = new Howl({
    src: ['assets/sound/scroll.flac'],
    volume: 0.4
  });
  fxWololo = new Howl({
    src: ['assets/sound/wololo.mp3'],
    volume: 0.4
  });
  fxGong = new Howl({
    src: ['assets/sound/Halo.mp3'],
    volume: 0.4
  });

  persoList: any[] = [];
  currentPerso: any;

  scale = 1.5;
  scaling = false;

  ngOnInit() {
    this.persoList[0] = {
      nickname: 'argurios',
      name: 'Argurios',
      OF: 122,
      powers: [
        { id: 39 }, { id: 43 }
      ],
      divinations: [],
      notes : localStorage.getItem('notes-argurios'),
      background: '<p>Argurios fut orphelin dès le plus jeune âge, car pour une raison inconnue sa famille fut frappée d\'une terrible malédiction. Des serviteurs d\'Hadès vinrent et emportèrent un à un les membres sa famille. Sa mère préféra abandonner son nourrisson dans une clairière sacrée en demandant à Athéna, déesse protectrice de sa famille, de secourir l\'enfant.</p>'
      + '<p>Athéna l\'entendit et demanda à Chiron de prendre soin de l\'enfant.</p>'
      + '<p>L\'enseignement de Chiron fit de lui un robuste et vaillant guerrier ainsi qu\'un jeune homme instruit. Il développa un goût prononcé pour la connaissance, la sagesse, l\'éducation physique, l\'esprit de camaraderie et la discipline guerrière.  Athéna le bénit du don de double-vue pour le prémunir des agents d\'Hadès. Il voit donc les esprits de toutes sortes qui habitent le monde.</p>'
      + '<p>Enfin, Athena suscita en lui la curiosité et le désir de comprendre afin qu\'un jour peut-être il arrive à mettre un terme à la malédiction qui pèse sur lui.</p>'
      + '<p>Etant confronté depuis toujours à la mort à cause de l\'influence d\'Hadès, Argurios a longuement réfléchi sur le sens de la vie et le caractère éphémère de l\'existence de ses semblables. Jusqu\'à aujourd\'hui, il a beaucoup voyagé et il a rencontré différents initiés et sages du Péloponèse et de l\'Attique. Alors qu\'il séjournait à Athènes sous le statut de métèque, il s\'illustra dans une bataille pour la défense de la cité. Il sauva la vie d\'un illustre citoyen, prêtre d\'Athéna, Thémistocle. Thémistocle l\'adopta et lui porta une grande affection. Argurios gagna ainsi la citoyenneté athénienne. Cependant son père adoptif mourut trois ans plus tard d\'un mal inconnu des médecins. Peu avant sa mort, il lui révéla une vision qu\'il avait eue : une personne venant de la mer lui porterait secours, son destin serait lié à celui de cette personne. L\'ombre de Thémistocle erre depuis sur les bords du Styx ; des agents d\'Hadès l\'empêche de trouver le repos.</p>'
      + '<p>Argurios est versé dans la mystique et a été initié à différents rites ; il porte une dévotion particulière empreinte de gratitude envers la déesse Athéna. Il aime écrire des aphorismes, des poèmes, des textes dans lesquels il livre ses pensées, ses intuitions et ses visions. La Pythie de Delphes lui a annoncé qu\'il était destiné à jouer un rôle important pour Athéna et que la déesse qui l\'avait guidé depuis son enfance avait des visées ambitieuses le concernant.</p>'
    };
    this.persoList[1] = {
      nickname: 'nikos',
      name: 'Nikos Aliagas',
      OF: 439,
      powers: [
        { id: 125 } , { id: 128 }
      ],
      divinations: [],
      notes : localStorage.getItem('notes-nikos'),
      background: '<p>[Marchand d\'esclave]</p>'
      + '<p>[Relation amicale avec des pirates]</p>'
      + '<p>[Vend le fils d\'un riche négociant egyptien]</p>'
      + '<p>[Représaille d\'une guilde egyptienne]</p>'
      + '<p>'
      + 'Ce jour-là, Nikos manqua de peu son rendez-vous avec la mort. Mais l\'Hadès devait attendre... En effet prenant alors les dieux à témoin, il fit admettre à ses ravisseurs qu\'il était plus juste et avisé de le faire esclave puisque son crime était d\'avoir fait esclave un homme libre.'
      + '</p>'
      + '<p>'
      + 'Les aegyptiens le trainèrent donc sans mot ni ménagement. Il le ligotèrent tout d\'abord, puis le jetèrent au fond d\'une cale infâme. Les jours passèrent, interminables... et alors qu\'il songeait ne plus revoir le soleil dans cette vie, on le fit sortir. C\'est ainsi que se dévoila sous son regard, pour la première fois, le spectacle animé de l\'antique cité de Memphis. On le traina, une fois de plus sans égar, jusqu\'à la place poussiéreuse du marché aux esclaves, où il fut vendu difficilement et à vil prix.'
      + '</p>'
      + '<p>'
      + 'C\'est un jeune  apprenti-architecte, appelé Hâkarêibi, qui se résigna à l\'acheter ce jour-là faute de trésorerie. Etant responsable d\'un chantier pour une des nécroples de la cité, il avait besoin de remplacer d\'urgence un ouvrier malade pour la basse oeuvre à pratiquer hors des sépultures.'
      + '</p>'
      + '<p>'
      + 'Les jours et les semaines passèrent... Nikos ne se distingua nullement par la qualité de son travail, mais cela ne l\'empêcha pas de se faire apprécier de son maître et de ses ouvriers, car en tant qu\'homme de commerce il entendait quelque peu l\'aegyptien. Malgré lui il apprit ainsi les rudiments du travail des métaux et de la construction. Constatant l\'omniprésence de la religion dans les éléments de la vie quotidienne des aegyptiens, il emprunta les us et coutumes locales, et se découvrit soudain une âme de dévot. Son zèle et son assiduité furent bien vite remarquer lors des rites du temple d\'Osiris, dieu de la mort, comme à ceux de l\'oratoire de Bastet, déesse de la fertilité et de la momification. Hâkarêibi l\'introduisit ainsi dans le culte de Bastet, où il fut initié à ses mystères.'
      + '</p>'
      + '<p>[Retour dans les cyclades]</p>'
      + '<p>[Piraterie]</p>'
      + '<p>[Batailles navales -> Typhon]</p>'
      + '<p>[Echoue sur les plages de la cité d\'Athènes]</p>'
      + '<p>[Recueilli par les prêtre]</p>'
      + '<p>[Témoin de la mort de Témistocle]</p>'
      + '<p>[Sauve la mise à Argurios accusé à tort]</p>'
    };
    this.initMusic();
    this.currentPerso = this.persoList[0];

    for (const perso of this.persoList) {
      const divivinations = localStorage.getItem('divinations-' + perso.nickname);
      if (divivinations) {
        perso.divinations = JSON.parse(divivinations);
      }
    }
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.currentPerso = this.persoList[tabChangeEvent.index];
  }

  initMusic() {
    this.music = new Howl({
      src: ['assets/sound/LevonMinassian.mp3'],
      autoplay: false,
      loop: true,
      volume: 0.2,
      onfade: soundId => {
        if (this.music.volume() === 0) {
          this.music.pause();
          this.musicON = false;
          localStorage.setItem('music-ON', 'false');
        } else {
          this.musicON = true;
          localStorage.setItem('music-ON', 'true');
        }
      },
      onload: () => {
        this.musicON = true;
      }
    });
    this.playMusic();
  }

  toggleMusic() {
    if (this.musicON) {
      this.stopMusic();
    } else {
      this.playMusic();
    }
  }

  stopMusic() {
    this.music.fade(0.2, 0, 2000);
  }

  playMusic() {
    this.music.play();
    this.music.fade(0, 0.2, 2000);
  }

  soundCard() {
    this.fxCard.play();
  }
  saveNotes(perso: string, notes: string) {
    localStorage.setItem('notes-' + perso, notes);
  }

  flip() {
    this.flipped = !this.flipped;
    this.soundCard();
  }

  switchMode() {
    this.soundCard();
    if (this.mode === 'game') {
      this.mode = 'story';
    } else {
      this.mode = 'game';
    }
  }

  openMap(): void {
    this.soundCard();
    this.displayMap = true;
  }

  closeModal() {
    this.selectedCard = null;
    this.selectedCardFile = null;
    this.displayMap = false;
  }

  displayDivinationCard(id: string) {
    const selected = 'divination-card-' + id;
    if (this.selectedCard && this.selectedCard === selected) {
      this.selectedCard = null;
      this.selectedCardFile = null;
    } else {
      this.selectedCard = 'divination-card-' + id;
      this.selectedCardFile = 'Carte_divination_antika' + id + '.jpg';
    }
    this.soundCard();
  }

  displayPowerCard(id: string) {
    const selected = 'power-card-' + id;
    if (this.selectedCard && this.selectedCard === selected) {
      this.selectedCard = null;
      this.selectedCardFile = null;
    } else {
      this.selectedCard = 'power-card-' + id;
      this.selectedCardFile = 'Carte_pouvoir_antika' + id + '.jpg';
    }
    this.soundCard();
  }

  doDivination() {
    if (!this.currentPerso.divinations) {
      this.currentPerso.divinations = [];
    }
    const divinationId = this.divinationService.addRandom(this.currentPerso);

    if (divinationId) {
      this.fxWololo.play();
      this.fxGong.play();

      this.selectedCard = 'divination-card-' + divinationId;
      this.selectedCardFile = 'Carte_divination_antika' + divinationId + '.jpg';
    }
  }

  removeDivination(idx: number) {
    delete this.currentPerso.divinations[idx];
    this.currentPerso.divinations = this.currentPerso.divinations.filter(obj => obj !== null);

    this.divinationService.saveDivinations(this.currentPerso);

    this.fxCard.play();
  }

  getDivinationName(id: Number) {
    return this.divinationService.getName(id);
  }

  goAccueil() {
    this.stopMusic();
    console.log('goAccueil');
    this.toIndex.emit('');
  }

  clickMap(e: any) {
    const lens = document.getElementById('imgZoom');
    const offsetX = document.getElementById('imgZoom').offsetLeft;
    this.scaling = true;
    if (this.scale < 3.5) {
      this.scale = this.scale * 1.33;
    } else {
      this.scale = 1.5;
    }

    // TweenMax.to('#imgZoom', 0.1, {attr: {width: w * scale, height: h * scale, x: offsetX * (scale - 1) - e.pageX * (scale - 1), y: -e.pageY * (scale - 1)}, onComplete: function() {scaling = false; }});
  }

  mousemoveMap(e: any) {
    const lens = document.getElementById('imgZoom');
    const masker = document.getElementById('masker');
    const mag = document.getElementById('mag');
    if (!this.scaling) {
      // TweenMax.to('#mag',     0.3, {x: e.pageX, y: e.pageY});
      mag.setAttribute('x', e.pageX + 'px');
      mag.setAttribute('y', e.pageY + 'px');
      // TweenMax.to('#masker', 	0.3, {attr: {cx: e.pageX, cy: e.pageY}});
      masker.setAttribute('cx', e.pageX + 'px');
      masker.setAttribute('cy', e.pageY + 'px');
      // TweenMax.to('#imgZoom', 0.3, {attr: {x: e.pageX * (this.scale - 1), y: -e.pageY * (this.scale - 1)}});
      lens.setAttribute('x', e.pageX * (this.scale - 1) + 'px');
      lens.setAttribute('y', -e.pageY * (this.scale - 1) + 'px');
    }
  }
  mouseenterMap(e: any) {
    const lens = document.getElementById('imgZoom');
    const masker = document.getElementById('masker');
    const mag = document.getElementById('mag');
    lens.style.opacity = '1';
    masker.style.opacity = '1';
    mag.style.opacity = '1';
  }

  mouseleaveMap(e: any) {
    const lens = document.getElementById('imgZoom');
    const masker = document.getElementById('masker');
    const mag = document.getElementById('mag');
    lens.style.opacity = '0';
    masker.style.opacity = '0';
    mag.style.opacity = '0';
  }

}
