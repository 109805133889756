import { Feature } from './feature';

export class MiscFeature extends Feature {

    constructor() {
        super();
        this.type = 'Misc';
    }

}
