import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  game = '';
  musicON = true;
  music: Howl;
  fxCard = new Howl({
    src: ['assets/sound/sound_card.mp3']
  });

  ngOnInit() {
    this.initMusic();
  }

  initMusic() {
    this.music = new Howl({
      src: ['assets/sound/CityDocksDay.mp3'],
      autoplay: false,
      loop: true,
      volume: 0.5,
      onfade: soundId => {
        if (this.music.volume() === 0) {
          this.music.pause();
          this.musicON = false;
          localStorage.setItem('music-ON', 'false');
        } else {
          this.musicON = true;
          localStorage.setItem('music-ON', 'true');
        }
      },
      onload: () => {
        this.musicON = true;
      }
    });
    this.playMusic();
  }

  toggleMusic() {
    if (this.musicON) {
      this.stopMusic();
    } else {
      this.playMusic();
    }
  }

  stopMusic() {
    this.music.fade(0.5, 0, 2000);
  }

  playMusic() {
    this.music.play();
    this.music.fade(0, 0.5, 2000);
  }

  soundCard() {
    this.fxCard.play();
  }

  reload() {
    console.log('reload');
    this.game = '';
  }
}
