export class Dice {

    static d4 = 'd4';
    static d6 = 'd6';
    static d8 = 'd8';
    static d10 = 'd10';
    static d12 = 'd12';
    static d20 = 'd20';

}
