import { Culture } from './culture.model';

export class Virtue {
    static BIRTHRIGHT: Virtue = { culture: Culture.BARDING };
    static FIERCE_SHOT: Virtue = { culture: Culture.BARDING };
    static KINGS_MEN: Virtue = { culture: Culture.BARDING };
    static SWORDMASTER: Virtue = { culture: Culture.BARDING };
    static WOEFUL_FORESIGHT: Virtue = { culture: Culture.BARDING };

    static BROTHERS_TO_BEARS: Virtue = { culture: Culture.BEORNING };
    static GREAT_STRENGTH: Virtue = { culture: Culture.BEORNING };
    static NIGHT_GOER: Virtue = { culture: Culture.BEORNING };
    static SKIN_COAT: Virtue = { culture: Culture.BEORNING };
    static TWICE_BAKED_HONEY_CAKES: Virtue = { culture: Culture.BEORNING };

    static DAUNTLESS_GUARDIANS: Virtue = { culture: Culture.DUNEDAIN };
    static SEE_THE_UNSEEN: Virtue = { culture: Culture.DUNEDAIN };
    static STRENGTH_OF_WILL: Virtue = { culture: Culture.DUNEDAIN };
    static WRAITH_BANE: Virtue = { culture: Culture.DUNEDAIN };
    static ENDURANCE_OF_THE_DUNEDAIN: Virtue = { culture: Culture.DUNEDAIN };
    static FORESIGHT_OF_THEIR_KINDRED: Virtue = { culture: Culture.DUNEDAIN };
    static ROYALTY_REVEALED: Virtue = { culture: Culture.DUNEDAIN };
    static STERNER_THAN_STEEL: Virtue = { culture: Culture.DUNEDAIN };

    static BROKEN: Virtue = { culture: Culture.DUNEDAIN };

    culture?: Culture;

}
