export class Tools {
    static BREWERS_SUPPLIES: Tools = { id: 'tools.BREWERS_SUPPLIES'};
    static CALLIGRAPHERS_SUPPLIES: Tools = { id: 'tools.CALLIGRAPHERS_SUPPLIES'};
    static CARPENTERS_TOOLS: Tools = { id: 'tools.CARPENTERS_TOOLS'};
    static WOODCARVER_TOOLS: Tools = { id: 'tools.WOODCARVER_TOOLS'};
    static CARTOGRAPHERS_TOOLS: Tools = { id: 'tools.CARTOGRAPHERS_TOOLS'};
    static COBBLERS_TOOLS: Tools = { id: 'tools.COBBLERS_TOOLS'};
    static COOKS_USTENSILS: Tools = { id: 'tools.COOKS_USTENSILS'};
    static DICE_SET: Tools = { id: 'tools.DICE_SET'};
    static GAMING_SET: Tools = { id: 'tools.GAMING_SET'};
    static GLASSBLOWERS_TOOLS: Tools = { id: 'tools.GLASSBLOWERS_TOOLS'};
    static HERBALISM_KIT: Tools = { id: 'tools.HERBALISM_KIT'};
    static JEWELLERS_TOOLS: Tools = { id: 'tools.JEWELLERS_TOOLS'};
    static LEATHERWORKERS_TOOLS: Tools = { id: 'tools.LEATHERWORKERS_TOOLS'};
    static MASONS_TOOLS: Tools = { id: 'tools.MASONS_TOOLS'};
    static PAINTERS_SUPPLIES: Tools = { id: 'tools.PAINTERS_SUPPLIES'};
    static POTTERS_TOOLS: Tools = { id: 'tools.POTTERS_TOOLS'};
    static SMITHS_TOOLS: Tools = { id: 'tools.SMITHS_TOOLS'};
    static TINKERS_TOOLS: Tools = { id: 'tools.TINKERS_TOOLS'};
    static WEAVERS_TOOLS: Tools = { id: 'tools.WEAVERS_TOOLS'};
    static PIPE_SMOKING_SUPPLIES: Tools = { id: 'tools.PIPE_SMOKING_SUPPLIES'};
    static POISON_TOOLS: Tools = { id: 'tools.POISON_TOOLS'};
    static THIEVES_TOOLS: Tools = { id: 'tools.THIEVES_TOOLS'};
    static INSTRUMENT_DRUM: Tools = { id: 'tools.INSTRUMENT_DRUM'};
    static INSTRUMENT_FIDDLE: Tools = { id: 'tools.INSTRUMENT_FIDDLE'};
    static INSTRUMENT_FLUTE: Tools = { id: 'tools.INSTRUMENT_FLUTE'};
    static INSTRUMENT_LUTE: Tools = { id: 'tools.INSTRUMENT_LUTE'};
    static INSTRUMENT_LYRE: Tools = { id: 'tools.INSTRUMENT_LYRE'};
    static INSTRUMENT_HORN: Tools = { id: 'tools.INSTRUMENT_HORN'};
    static INSTRUMENT_VIOL: Tools = { id: 'tools.INSTRUMENT_VIOL'};
    static INSTRUMENT_HARMONICA: Tools = { id: 'tools.INSTRUMENT_HARMONICA'};
    static INSTRUMENT_OTHER: Tools = { id: 'tools.INSTRUMENT_OTHER'};

    static ALL: Map<string, Tools> = new Map<string, Tools>([
        ['tools.BREWERS_SUPPLIES', Tools.BREWERS_SUPPLIES],
        ['tools.CALLIGRAPHERS_SUPPLIES', Tools.CALLIGRAPHERS_SUPPLIES],
        ['tools.CARPENTERS_TOOLS', Tools.CARPENTERS_TOOLS],
        ['tools.WOODCARVER_TOOLS', Tools.WOODCARVER_TOOLS],
        ['tools.CARTOGRAPHERS_TOOLS', Tools.CARTOGRAPHERS_TOOLS],
        ['tools.COBBLERS_TOOLS', Tools.COBBLERS_TOOLS],
        ['tools.COOKS_USTENSILS', Tools.COOKS_USTENSILS],
        ['tools.DICE_SET', Tools.DICE_SET],
        ['tools.GAMING_SET', Tools.GAMING_SET],
        ['tools.GLASSBLOWERS_TOOLS', Tools.GLASSBLOWERS_TOOLS],
        ['tools.HERBALISM_KIT', Tools.HERBALISM_KIT],
        ['tools.JEWELLERS_TOOLS', Tools.JEWELLERS_TOOLS],
        ['tools.LEATHERWORKERS_TOOLS', Tools.LEATHERWORKERS_TOOLS],
        ['tools.MASONS_TOOLS', Tools.MASONS_TOOLS],
        ['tools.PAINTERS_SUPPLIES', Tools.PAINTERS_SUPPLIES],
        ['tools.POTTERS_TOOLS', Tools.POTTERS_TOOLS],
        ['tools.SMITHS_TOOLS', Tools.SMITHS_TOOLS],
        ['tools.TINKERS_TOOLS', Tools.TINKERS_TOOLS],
        ['tools.WEAVERS_TOOLS', Tools.WEAVERS_TOOLS],
        ['tools.PIPE_SMOKING_SUPPLIES', Tools.PIPE_SMOKING_SUPPLIES],
        ['tools.POISON_TOOLS', Tools.POISON_TOOLS],
        ['tools.THIEVES_TOOLS', Tools.THIEVES_TOOLS],
        ['tools.INSTRUMENT_DRUM', Tools.INSTRUMENT_DRUM],
        ['tools.INSTRUMENT_FIDDLE', Tools.INSTRUMENT_FIDDLE],
        ['tools.INSTRUMENT_FLUTE', Tools.INSTRUMENT_FLUTE],
        ['tools.INSTRUMENT_LUTE', Tools.INSTRUMENT_LUTE],
        ['tools.INSTRUMENT_LYRE', Tools.INSTRUMENT_LYRE],
        ['tools.INSTRUMENT_HORN', Tools.INSTRUMENT_HORN],
        ['tools.INSTRUMENT_VIOL', Tools.INSTRUMENT_VIOL],
        ['tools.INSTRUMENT_HARMONICA', Tools.INSTRUMENT_HARMONICA],
        ['tools.INSTRUMENT_OTHER', Tools.INSTRUMENT_OTHER],
    ]);

    id: string;
}
